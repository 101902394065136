<div class="main__header">
  <div class="main__title">Cambio de contraseña</div>
  <div class="main__subtitle">
    Por favor ingrese su actual contraseña
  </div>
</div>

<form [formGroup]="resetPasswordForm" autocomplete="off">
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Actual contraseña</mat-label>
        <input matInput [type]="hideOld ? 'password' : 'text'" formControlName="oldPassword" name="oldPassword">
        <button type="button" mat-icon-button matSuffix (click)="hideOld = !hideOld" [attr.aria-label]="'Ocultar clave'"
          [attr.aria-pressed]="hideOld">
          <mat-icon>{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
  </div>
  <div class="row mt-3">
    <div class="col">
      A continuacion ingrese su nueva contraseña
      <p>
        <small style="font-size:.7rem;line-height:0rem;">*La nueva contraseña debe tener por lo menos 8 caracteres y contener letras, numeros y uno de los
          siguientes caracteres #?!@$%^&*-</small>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Nueva contraseña</mat-label>
        <input matInput type="password" formControlName="password" name="password" [type]="hide ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Ocultar clave'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <!-- <mat-error *ngIf="addressForm.controls['city'].hasError('required')"></mat-error> -->
        <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required')">
          Nueva contraseña requerida
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('minlength')">
          Debe contener minimo 8 caracteres
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('pattern')">
          No contiene los caracteres requeridos
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Confirmar nueva contraseña</mat-label>
        <input matInput type="password" formControlName="passwordConfirm" name="password-confirm"
          [type]="hideTwo ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hideTwo = !hideTwo" [attr.aria-label]="'Ocultar clave'"
          [attr.aria-pressed]="hideTwo">
          <mat-icon>{{hideTwo ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="resetPasswordForm.controls['passwordConfirm'].hasError('required')">
          Confirmacion de contraseña requerida
        </mat-error>
      </mat-form-field>
      <small *ngIf="passwordNoSonIguales" class="error-message">
        *Las contraseña no coinciden
      </small>
    </div>
    <div class="col-md-4"></div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <button mat-flat-button color="primary" class="button-login" (click)="cambiarPassword()" [disabled]="resetPasswordForm.invalid">Cambiar
        contraseña</button>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
  </div>
</form>