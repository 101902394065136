import { Component, OnInit, inject } from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';
import { UsuarioService } from "../../../modules/auth/services/usuario.service";
import { SocketService } from '../../services/socket.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss'],
  styles: [
    `
    :host {
      display: flex;
    }

    .example-pizza-party {
      color: hotpink;
    }
  `,
  ],
})

export class NotificacionesComponent {
  durationInSeconds = 5;
  badgeCount: number;
  notificacion: any[] = []; 
 // snackBarRef = inject(MatSnackBarRef);
  constructor(private _snackBar: MatSnackBar,private router: Router,    
    public _usuarioService: UsuarioService, private socketService :SocketService,) {}
    redireccionar(idTipoTramite: number) {
      let rutaDestino = '';
  
      switch (idTipoTramite) {
        case 8: ////para autorizacion previa
          rutaDestino = '/licencias-previas/bandeja-licencias-previas/lista';
          break;
        case 12:////para nuevos registros
          rutaDestino = '/registros/fuente-registros/lista';
          break;
        case 33:////para notificaciones de autorizaciones previas
          rutaDestino = '/licencias-previas/notificaciones-licencias-previas/lista-notificaciones';
          break;
        case 2:////para compras
          rutaDestino = '/compras/compra-local/lista';
          break;
        case 3:////para hojas de ruta
          rutaDestino = '/hojas/lista';
          break;
        case 31:////para entrega
          rutaDestino = '/entrega/lista';
          break;
        case 32:////para destruccion
          rutaDestino = '/destruccion/lista';
          break;
        // Agrega más casos para otros tipos de trámite
        default:
          break;
      }  
      this.router.navigateByUrl(rutaDestino);
    }
    //
  async ngOnInit() {
    this.notificaciones();
/*     this.socketService.getNewMessage().subscribe((data)=>{
      console.log("socketService",data)
      this.notificaciones();
    }) */
  }

   async notificaciones() {
    try {
      var respuesta = await lastValueFrom(this._usuarioService.notificaciones()
      );
         //console.log("respuesta notificaciones", respuesta.datos.result)
         const elementosConPlazoVencidoFalse = respuesta.datos.result.filter(item => item.plazo_vencido === false || item.plazo_vencido === null);

         this.badgeCount = elementosConPlazoVencidoFalse.length;
         this.notificacion = respuesta.datos.result.filter(item => item.plazo_vencido === false || item.plazo_vencido === null);
    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }
  
  }


}