
      <!-- notificacion -->
      <div class="toolbar__initial-notification" [matMenuTriggerFor]="notify"  >
        <mat-icon matBadge="{{badgeCount}}" matBadgeColor="warn">
          <img src="assets/gif/iconnotificacion.gif" alt="" height="40" width="40">
        </mat-icon>
      
        <mat-menu #notify="matMenu" class="profile-menu"  >
          <div class="row" style="margin: 0.3em; text-align: center; font-size: .8rem;">
            <h4>Notificaciones</h4>
          </div>
          <mat-divider></mat-divider>
      
          <button mat-menu-item *ngFor="let notificaciones of notificacion"
          style="height: 5rem; text-align: left; font-size: .75rem; line-height: 1rem; padding: 1.7rem;"
          [ngClass]="{
            'custom-button-class-1': (notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null) && notificaciones.id_tipo_tramite === 8,
            'custom-button-class-2': (notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null) && notificaciones.id_tipo_tramite === 12,
            'custom-button-class-3': (notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null) && notificaciones.id_tipo_tramite === 33,
            'custom-button-class-4': (notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null) && notificaciones.id_tipo_tramite === 2,
            'custom-button-class-5': (notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null) && notificaciones.id_tipo_tramite === 3,
            'custom-button-class-6': (notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null) && notificaciones.id_tipo_tramite === 32,
            'custom-button-class-7': (notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null) && notificaciones.id_tipo_tramite === 31
          }"
          (click)="redireccionar(notificaciones.id_tipo_tramite)"
         >
    <div  *ngIf="notificaciones.plazo_vencido === false || notificaciones.plazo_vencido === null">
      <mat-icon aria-hidden="false" *ngIf="notificaciones.id_tipo_tramite === 8">flight</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="notificaciones.id_tipo_tramite === 12">app_registration</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="notificaciones.id_tipo_tramite === 33">description</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="notificaciones.id_tipo_tramite === 2">shopping_cart</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="notificaciones.id_tipo_tramite === 3">local_shipping</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="notificaciones.id_tipo_tramite === 32">layers_clear</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="notificaciones.id_tipo_tramite === 31">inbox</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="!notificaciones.id_tipo_tramite"></mat-icon>
      {{notificaciones.nombre_tipo_tramite}} <br>
      {{notificaciones.mensaje}} {{ notificaciones.numero_tramite }}
    </div>
  </button>

        </mat-menu>
      </div>
      <!-- notificacion -->
