<div class="user-container">
  <div class="row user-height justify-content-center align-items-center">
    <div class="col-md-8 container">
      <div class="user-container__box">

        <div class="user-container__head">
          <div class="user-container__head head__logo">
            <img src="assets/images/logo2021.jpg" alt="DGSC" />
          </div> <br>
          <mat-card-title
            style="text-align: center; font-size: 20px;margin-bottom: 1rem;"><br>{{authTitle}}</mat-card-title>
          <mat-card-title style="text-align: center;  font-size: 1.3vh;"><br>{{authSubtitle}}</mat-card-title>
          <br>
          <mat-card-subtitle class="subtitle"><strong>RESTABLECIMIENTO DE CONTRASEÑA</strong></mat-card-subtitle>

        </div>



        <mat-horizontal-stepper class="registro-usuario-stepper" [linear]="true" #stepper>

          <mat-step [completed]="secondStepComplete" label="Restablecer contraseña" [editable]="false">
            <div class="">
              <div class="step-contain step-contain--center">
                <form [formGroup]="recuperaForm" autocomplete="off">
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Correo electrónico</mat-label>
                        <input matInput type="email" formControlName="correo" name="correo">
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Contraseña nueva</mat-label>
                        <input matInput type="password" formControlName="password" name="password"
                          [type]="hide ? 'password' : 'text'">
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                          [attr.aria-label]="'Ocultar clave'" [attr.aria-pressed]="hide">
                          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="recuperaForm.controls['password'].hasError('required')">
                          Contraseña requerida
                        </mat-error>
                        <mat-error *ngIf="recuperaForm.controls['password'].hasError('minlength')">
                          Debe contener mínimo 8 caracteres
                        </mat-error>
                        <mat-error *ngIf="recuperaForm.controls['password'].hasError('pattern')">
                          No contiene los caracteres requeridos
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Confirmar contraseña</mat-label>
                        <input matInput type="password" formControlName="passwordConfirm" name="password-confirm"
                          [type]="hideTwo ? 'password' : 'text'">
                        <button type="button" mat-icon-button matSuffix (click)="hideTwo = !hideTwo"
                          [attr.aria-label]="'Ocultar clave'" [attr.aria-pressed]="hideTwo">
                          <mat-icon>{{hideTwo ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="recuperaForm.controls['passwordConfirm'].hasError('required')">
                          Confirmación de contraseña requerida
                        </mat-error>
                        <small *ngIf="passwordNoSonIguales" class="error-message">
                          Las contraseña no coinciden
                        </small>
                      </mat-form-field>

                      <div>
                        <small style="font-size:.7rem; color: #a6a6a6">*La contraseña debe contener letras,
                          nómeros y uno de los siguientes caracteres #?!@$%^&*-</small>
                      </div>

                    </div>

                  </div>
                </form>
              </div>
              <div class="step-ffooter">
                <button mat-flat-button class="nuevo-button" matStepperNext [disabled]="!formsAreValid()"
                  (click)="solicitarRestablecerClave()">Restablecer contraseña</button>
              </div>
            </div>
          </mat-step>

          <mat-step [completed]="thirdStepComplete" label="Introducir Código" [editable]="false">
            <div class="step-body">
              <div class="step-contain step-contain--center">

                <form [formGroup]="thirdFormGroup" autocomplete="off">
                  <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Código</mat-label>
                        <input matInput type="text" formControlName="codigo" name="codigo" maxlength="6">
                      </mat-form-field>
                    </div>
                    <div class="col-4"></div>
                  </div>
                </form>

                <div class="row mt-4">
                  <div class="col text-center">
                    Se le ha enviado un código de verificación a su correo electrónico. Por favor, revise su bandeja de
                    entrada y proporcione el código de 6 dígitos correspondiente. Gracias.<br>
                    <small style="color: rgba(109, 118, 139, 1)">*Si no logra encontrarlo en la bandeja de entrada
                      principal, le sugerimos revisar también la carpeta de "Spam". Es posible que el correo haya sido
                      redirigido allí por error.</small>
                    <br><br>
                    <a (click)="solicitarCodigo()" style="color: #34b0a6; cursor: pointer; font-weight: 500;">Solicitar
                      nuevo código de activación</a>
                  </div>
                </div>
              </div>
              <div class="step-footer">
                <button mat-flat-button color="primary" (click)="restablecerClave()" matStepperNext>Verificar
                  código</button>
              </div>
            </div>
          </mat-step>

          <mat-step label="Finalización de restablecimiento">
            <div class="step-body">
              <div class="step-contain step-contain--center">
                <div *ngIf="isFormCompleted; then thenFinishBlock else elseFinishBlock">
                </div>
                <ng-template #thenFinishBlock>
                  <img src="assets/images/auth-check.png" alt="DGSC" />
                  <br>
                  <p style="font-size:1.3rem;font-weight:500;">La contraseña se ha restablecido exitosamente.</p>
                  <small>¡Puede acceder a <a routerLink="/login">Iniciar sesión</a> con la nueva contraseña!</small>
                </ng-template>
                <ng-template #elseFinishBlock>
                  <img src="assets/images/warning.png" alt="warning" width="80" />
                  <br>
                  <p style="font-size:1.3rem;font-weight:500;">Lamentablemente, no se ha podido restablecer la
                    contraseña en este momento. </p>
                  <small><a routerLink="/login">Por favor, inténtelo nuevamente más tarde</a> o inicie sesión con
                    otra cuenta.</small>
                </ng-template>
              </div>
              <div class="step-footer">
                <button mat-flat-button class="nuevo-button" *ngIf="isFormCompleted" routerLink="/login" >Finalizar</button>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>


        <div class="user-container__footer ">
          <div class="footer__option">
            ¿Ya tienes cuenta?
            <a routerLink="/login"> Inicia sesión</a>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>