import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as printJS from "print-js"

@Component({
  selector: 'app-navbar-pdf',
  templateUrl: './navbar-pdf.component.html',
  styleUrls: ['./navbar-pdf.component.scss']
})
export class NavbarPdfComponent implements OnInit {

  @Input() base64: any
  @Input() fileName: string
  secureBase64: SafeUrl

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.fileName += '.pdf'
    this.secureBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:application/pdf;base64,${this.base64}`)
  }

  print() {
    const blob = this.b64toBlob(this.base64)
    const blobUrl = URL.createObjectURL(blob)

    console.log('BLOB FILES', blob, blobUrl)

    printJS(blobUrl)
  }

  b64toBlob(b64Data, contentType = 'application/pdf', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}
