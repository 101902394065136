/**pruebas */
export const environment = {
  production: false,
  //base_url_backend: 'http://165.172.24.61:8200',
  base_url_backend: 'https://api.ed6.dgsc.gob.bo',
  //base_url: 'http://165.172.24.61:8200/api/v1/interno',
  base_url: 'https://api.ed6.dgsc.gob.bo/api/v1/interno',
  //base_url_sistema: 'http://165.172.24.61:8200/api/v1',
  base_url_sistema: 'https://api.ed6.dgsc.gob.bo/api/v1',
  base_urlFD: 'https://localhost:9000', 
};


/**local */
// export const environment = {
//   production: false,
//   base_url_backend: 'http://165.172.24.61:8200',
//   base_url: 'http://165.172.24.61:8200/api/v1/interno',
//   base_url_sistema: 'http://165.172.24.61:8200/api/v1',
//   base_urlFD: 'https://localhost:9000', 
// };
