import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AuthRoutingModule } from './modules/auth/auth-routing.module';

const routes: Routes = [
  { path: 'descargo', loadChildren: () => import(`./modules/descargos/descargos.module`).then(m => m.DescargosModule) },
  { path: 'dashboard', loadChildren: () => import(`./modules/dashboard/dashboard.module`).then(m => m.DashboardModule) },
  { path: 'admin', loadChildren: () => import(`./modules/usuarios/usuarios.module`).then(m => m.UsuariosModule) },
  { path: 'laboratorio', loadChildren: () => import(`./modules/laboratorio/laboratorio.module`).then(m => m.LaboratorioModule) },
  { path: 'registros', loadChildren: () => import(`./modules/registros/registros.module`).then(m => m.RegistrosModule) },
  { path: 'licencias-previas', loadChildren: () => import('././modules/licencias-previas/licencias-previas-module').then(m => m.LicenciasPreviasModule) },
  { path: 'tramites', loadChildren: () => import('././modules/tramites/tramites.module').then(m => m.TramitesModule) },
  { path: 'compras', loadChildren: () => import('././modules/compras/compras.module').then(m => m.ComprasModule) },
  { path: 'hojas', loadChildren: () => import('././modules/hojas/hojas.module').then(m => m.HojasModule) },
  { path: 'inspecciones', loadChildren: () => import('././modules/inspecciones/inspecciones.module').then(m => m.InspeccionesModule) },
  { path: 'interoperabilidad', loadChildren: () => import('././modules/interoperabilidad/interoperabilidad.module').then(m => m.InteroperabilidadModule) },

  { path: 'conciliaciones', loadChildren: () => import('./modules/conciliaciones/conciliaciones.module').then(m => m.ConciliacionesModule) },
  { path: 'reportes', loadChildren: () => import('./modules/conciliaciones/conciliaciones.module').then(m => m.ConciliacionesModule) },
  { path: 'correspondencia', loadChildren: () => import('./modules/correspondencia/correspondencia.module').then(m => m.CorrespondenciaModule) },
  { path: 'infraccion', loadChildren: () => import('./modules/infracciones/infracciones.module').then(m => m.InfraccionesModule) },
  { path: 'legalizar', loadChildren: () => import('./modules/legalizaciones/legalizaciones.module').then(m => m.LegalizacionesModule) },
  { path: 'dispositivos', loadChildren: () => import('./modules/dispositivos/dispositivos.module').then(m => m.DispositivosModule) },
  { path: 'viajes', loadChildren: () => import('./modules/viajes/viajes.module').then(m => m.ViajesModule) },
  { path: 'entrega', loadChildren: () => import('./modules/entregas/entregas.module').then(m => m.EntregasModule) },
  { path: 'destruccion', loadChildren: () => import('./modules/destruccion/destruccion.module').then(m => m.DestruccionModule) },
  /*   {path: '',redirectTo:'auth',pathMatch: 'full'}, */
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
    AuthRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }