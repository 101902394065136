<mat-card>

  <form [formGroup]="formImagenes">
    <mat-card-title style="text-align: center; font-size: 20px;"  class="main__title">IMÁGENES ADICIONALES</mat-card-title>
    <span style="font-weight: bold;color: #f83f49;">Por favor, asegúrese de adjuntar (4) imágenes importantes: 
      fachada frontal, área de almacenamiento, maquinarias, instalaciones de manejo de sustancias y equipos.</span>
  <hr size="5px">
    <div class="row modal__header">
    <!--   <div class="col">
        
        <div style="font-size: 1.2rem;color: #ff4f58;">Por favor, asegúrese de adjuntar las 4 imágenes importantes.</div>
      </div> -->
      <div class="col text-end">
        <button mat-raised-button class="mx-3" class="nuevo-button"  matTooltip="Agregar Fotografías" imagen style="height: 45px"
        (click)="agregarImagenAdicional()">
        <mat-icon>add_photo_alternate</mat-icon>
        &nbsp;Agregar imagen
      </button>
       <!--  <button mat-stroked-button color="simple-outline" class="my-3" aria-label="Agregar imagen"
          (click)="agregarImagenAdicional()">
          <mat-icon>add_photo_alternate</mat-icon>
          &nbsp;Agregar imagen
        </button> -->
      </div>
    </div>

    <div class="my-3" formArrayName="imagenes_adicionales">
      <div *ngIf="imagenesAdicionales.value.length > 0; then nonEmptyBlock else emptyBlock"></div>

      <ng-template #nonEmptyBlock>
        <div class="image-layer" *ngFor="let imagenAdicionalForm of imagenesAdicionales.controls; let i=index"
          [formGroupName]="i">
          <div style="height:30px;display:flex;justify-content:end;">

            <div (click)="eliminarImagen(imagenAdicionalForm, i)"
              style="display:inline-flex;align-items:center;font-size:11px;margin: 0 .3rem;cursor:pointer;">
              <img class="color-delete" src="assets/svg/delete_forever.svg"/>&nbsp; Eliminar
            </div>
            
            <div *ngIf="imagenAdicionalForm.value.guardado; then loadedBlock else notLoadedBlock"></div>

            <ng-template #notLoadedBlock>
              <div (click)="guardarImagenAdicional(imagenAdicionalForm)"
                style="display:inline-flex;align-items:center;font-size:11px;margin: 0 .3rem;cursor:pointer;">
                <img class="color-verified" src="assets/svg/save_white_24dp.svg"/> &nbsp;Guardar
              </div>
            </ng-template>

            <ng-template #loadedBlock>
              <div style="display:inline-flex;align-items:center;font-size:11px;">
                <mat-icon style="color:#28e075">verified</mat-icon>Guardado
              </div>
            </ng-template>
          </div>

          <!-- <div class="image-box" id="image-box-{{i}}"
            (click)="imagenAdicionalForm.value.guardado ? '' : openFileBrowser(i)"
            [matTooltip]="imagenAdicionalForm.value.guardado ? 'Imagen guardada' : imagenAdicionalForm.value.base64=='' ? 'Agregar imagen' : 'Cambiar imagen'">
          </div> -->
          <img class="image-box" [src]="imagenAdicionalForm.value.base64" id="image-box-{{i}}"
            (click)="imagenAdicionalForm.value.guardado ? '' : openFileBrowser(i)"
            [matTooltip]="imagenAdicionalForm.value.guardado ? 'Imagen guardada' : imagenAdicionalForm.value.base64=='' ? 'Agregar imagen' : 'Cambiar imagen'">

          <input type="file" name="imagen-{{i}}" id="imagen-{{i}}"
            (change)="adjuntarArchivo($event, imagenAdicionalForm, i)" style="display: none;"
            accept=".jpg, .jpeg, .png">
          <div class="image-label">
            <mat-form-field appearance="outline">
              <mat-label>Descripcion</mat-label>
              <textarea matInput placeholder="Descripcion" class="description-textarea" rows="3"
                formControlName="descripcion"></textarea>
            </mat-form-field>
          </div>
        </div>
      </ng-template>
      <ng-template #emptyBlock>
        <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;height:300px;">
          <img src="assets/images/empty-files.png" alt="Ningun archivo cargado">
          <span style="font-size:13px;color:#838383; margin-top:1rem;">Aún no ha adjuntado ninguna de las 4 imágenes importantes requeridas. Por favor, asegúrese de adjuntarlas.</span>
        </div>
          <!--   <button mat-stroked-button color="simple-outline" class="my-3" aria-label="Agregar imagen"
            (click)="agregarImagenAdicional()">
            <mat-icon>add_photo_alternate</mat-icon>
            &nbsp;Agregar imagen
          </button> -->
          <div mat-dialog-actions class="mt-3 mb-1">
          <button mat-raised-button class="nuevo-button" matTooltip=Agregar imagen style="height: 45px"
          (click)="agregarImagenAdicional()">
          <mat-icon>add_photo_alternate</mat-icon>
          Agregar imagen
        </button>&nbsp; 
        <button mat-raised-button color="warn" [mat-dialog-close]="false" style="height: 45px">
          <mat-icon>cancel</mat-icon>Cancelar
        </button>
        <!--   <button mat-flat-button class="mx-3" [mat-dialog-close]="false">Cancelar</button> -->
        </div>
      </ng-template>
    </div>

    <div class="row my-3 pb-4" *ngIf="imagenesAdicionales.value.length > 0">
      <div class="col text-end">
        <button mat-flat-button class="mx-3" (click)="cancelar()">Cancelar</button>
        <button mat-raised-button cdkFocusInitial color="primary" (click)="finalizar()" class="button button-primary"
          [disabled]="enableSave">
          Aceptar y terminar
        </button>
      </div>
    </div>

  </form>

</mat-card>