import { lastValueFrom } from 'rxjs'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Component, Inject, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { Parametricas } from 'src/app/core/enums/parametricas.enums'
import { ImagenService } from '../../services/imagen.service'
import { UsuarioService } from "src/app/modules/auth/services/usuario.service";


@Component({
  selector: 'app-carga-imagenes',
  templateUrl: './carga-imagenes.component.html',
  styleUrls: ['./carga-imagenes.component.scss']
})
export class CargaImagenesComponent implements OnInit {

  idTramite: number = 0
  idInspeccion: number = null
  enableSave: boolean = false
  imagenResponse: any

  formImagenes: FormGroup

  constructor(
    private usuarioService: UsuarioService,
    private imagenService: ImagenService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CargaImagenesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog

  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('870px', '565px')
    this.dialogRef.disableClose = true

    this.formImagenes = this.formBuilder.group({
      imagenes_adicionales: this.formBuilder.array([])
    })

    // Recibimos los documentos obligatorios como parametro de entrada al componente
    this.idTramite = this.data.idTramite
    this.idInspeccion = this.data.idInspeccion

    this.obtenerImagenes()
  }

  async obtenerImagenes() {
    try {
      this.imagenResponse = await lastValueFrom(this.imagenService.obtenerImagenes({
        idTramite: this.idTramite,
        idInspeccion: this.idInspeccion
      }))


      for (let image of this.imagenResponse.datos.imagenes) {
        this.agregarImagenAdicional(image)
      }
    } catch (error) {
  
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
   

  }

  get imagenesAdicionales() {
    return this.formImagenes.controls['imagenes_adicionales'] as FormArray
  }

  agregarImagenAdicional(imagenForm = null) {
    const imagenAdicionalForm = this.formBuilder.group({
      archivo: [imagenForm?.ruta ? imagenForm?.ruta : '', null],
      tipoArchivo: [imagenForm?.tipoArchivo ? imagenForm?.tipoArchivo : '', Validators.required],
      descripcion: [imagenForm?.descripcion ? imagenForm?.descripcion : '', Validators.required],
      base64: [imagenForm?.base64 ? imagenForm?.base64 : '', Validators.required],
      guardado: [imagenForm?.base64 ? true : false, null],
      idTramite: [this.idTramite, Validators.required],
      idInspeccion: [this.idInspeccion, null],
      idImagenInspeccion: [imagenForm?.id_imagen_inspeccion ? imagenForm?.id_imagen_inspeccion : null, null],
    })

    // Deshabilitamos la descripción de la imagen si la imagen esta guardada en la DB
    if (imagenAdicionalForm.value.guardado)
      imagenAdicionalForm.controls['descripcion'].disable()

    this.imagenesAdicionales.push(imagenAdicionalForm)
  }

  openFileBrowser(index: number) {
    document.getElementById('imagen-' + index).click()
  }

  adjuntarArchivo(event: any, form: FormGroup, index: number): void {
    if (event.target.files.length > 0) {
      form.patchValue({
        base64: ''
      })

      const size = event.target.files[0].size
      const fileType = event.target.files[0].type
      const fileName = event.target.files[0].name

      if (fileType !== 'image/jpg' && fileType !== 'image/jpeg' && fileType !== 'image/png') {
        Swal.fire({ text: 'Solo se aceptan archivos en formato .jpeg, jpg o .png', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      if (size > Parametricas.maxImageSize) {
        Swal.fire({ text: 'El archivo no puede tener mas de ' + Parametricas.maxImageSize / 1024 / 1024 + 'Mb de tamaño', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      form.patchValue({
        archivo: fileName
      })

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = async (ev: any) => {

        const docBase64 = ev.target.result

        // document.getElementById('image-box-' + index).style.backgroundImage = "url('" + ev.target.result + "')"
        document.getElementById('image-box-' + index)['src'] = ev.target.result

        form.patchValue({
          base64: docBase64,
          tipoArchivo: fileType
        })
      }
    }
  }

  async guardarImagenAdicional(form: FormGroup) {
    if (form.invalid) {
      Swal.fire({ text: 'Debe agregar una imagen y su descripción', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    // Enviar al backend pa' q se vaya guardando uno por uno cada imagen
    try {
      let imagenDB = await lastValueFrom(this.imagenService.guardarImagenAdicional(form.value))

      form.patchValue({
        guardado: true,
        idImagenInspeccion: imagenDB.datos.idImagenInspeccion
      })
    } catch (error) {
     
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    // Deshabilitamos la descripción de la imagen
    form.controls['descripcion'].disable()

    Swal.fire({ text: 'Se adjuntó una nueva imagen a su tramite', icon: 'success', confirmButtonText: 'Aceptar' })
  }

  async eliminarImagen(imagenAdicionalForm: FormGroup, index: number) {
    if (imagenAdicionalForm.value.guardado) {
      // Enviamos al backend para eliminarlo
      try {
        await lastValueFrom(this.imagenService.eliminarImagenAdicional({
          idTramite: imagenAdicionalForm.value.idTramite,
          idInspeccion: imagenAdicionalForm.value.idInspeccion,
          idImagenInspeccion: imagenAdicionalForm.value.idImagenInspeccion
        }))

        Swal.fire({ text: 'Imagen eliminada correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
      } catch (error) {
       
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
    }
    this.imagenesAdicionales.removeAt(index)
  }

  finalizar() {
    let filesNotSaved = 0
    if (this.imagenesAdicionales.value.length < 4) {
      Swal.fire({ text: 'Debe subir alemenos 4 fotografías', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    const idROL = this.usuarioService.usuario.idRol
    for (let imagenAdicional of this.imagenesAdicionales.value) {
      if (!imagenAdicional.guardado) {
        filesNotSaved++
      }
    }

    if (filesNotSaved == 0) {
      // Devolvemos true porque no existen documentos sin guardar
      this.dialogRef.close({
        success: true,
        listaImagenes: this.imagenesAdicionales.value
      })
    } else {
      Swal.fire({
        text: 'Algunas imagenes no fueron guardadas, desea salir de todos modos',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      })
        .then((result) => {
          if (result.isConfirmed) {
            // Devolvemos true porque ya se guardo todo correctamente
            this.dialogRef.close({
              success: true,
              listaImagenes: this.imagenesAdicionales.value
            })
          } else {
            return
          }
        })
    }
  }

  cancelar(){
    this.dialogRef.close({
      success : false,
      listaImagenes: this.imagenesAdicionales.value
    })
  }

}
