import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';

@Component({
  selector: 'app-cambiar-clave',
  templateUrl: './cambiar-clave.component.html',
  styleUrls: ['./cambiar-clave.component.scss']
})
export class CambiarClaveComponent implements OnInit {
  resetPasswordForm = this.formBuilder.group({
    oldPassword: ['', Validators.required],
    password: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
    ]],
    passwordConfirm: ['', [
      Validators.required,
      Validators.minLength(8),
    ]],
  });
  passwordNoSonIguales: boolean = false;
  hide = true;
  hideTwo = true;
  hideOld = true;

  ruta:'principal'

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private rutaActiva: ActivatedRoute
  ) { }

  ngOnInit(): void { 
    this.ruta = this.rutaActiva.snapshot.params.ruta;
/*     this.rutaActiva.queryParams.subscribe(
      params => {
        this.ruta =  params['ruta'];
      }
    ) */
    console.log(this.ruta)
  }

  async cambiarPassword() {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    // TODO: Validate data 
    if (this.resetPasswordForm.value.password !== this.resetPasswordForm.value.passwordConfirm) {
      this.passwordNoSonIguales = true;
      return;
    }

    Swal.fire({ title: '', text: 'Cambiando contraseña', icon: 'info', allowOutsideClick: false });
    Swal.showLoading();

    try {
      await this.usuarioService.cambiarClaveUsuario(this.resetPasswordForm.value).toPromise();
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'warning');
      return;
    }

    console.log(this.router);
    Swal.fire('', 'Su contraseña se ha cambiado correctamente', 'success');
    this.router.navigate([atob(this.ruta)]);
/*     let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    }); */
  }
}