import { CoreComponentsModule } from './core/components/core-components.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './modules/auth/auth.module';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

import { PdfViewerModule } from "ng2-pdf-viewer";
import { CommonModule } from '@angular/common';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import  { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { UpperCaseInputDirective } from './shared/directives/upper-case-input.directive';

@NgModule({
  declarations: [
    AppComponent,
    UpperCaseInputDirective 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule, 
    MaterialModule,
    AuthModule,
    PdfViewerModule,
    CoreComponentsModule,
    CKEditorModule
  ],
  exports:[
    MaterialModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: MAT_DATE_LOCALE, useValue: 'es-BO'},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          /* dateInput: ['l', 'LL'], */
          dateInput: 'dd/mm/yyyy',
        },
        display: {
          /* dateInput: 'L', */
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },  

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
