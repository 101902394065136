<div style="position: relative;" *ngIf="!hidden">

  <mat-horizontal-stepper class="mt-4" linear="true" #stepper>

    <mat-step [stepControl]="tiposPagoForm" label="Tipo de pago" style="padding: 0;" *ngIf="!importeDBEstaCompleto">
      <div class="mt-4 mb-5 text-center">
        <h2>Selecciona tu método de pago preferido</h2>
      </div>
      <div class="row mt-3">
        <div class="col">
          <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Tipo de tramite:</span>
            {{tipoTramite}}</p>
          <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Costo del tramite:</span> Bs.
            {{montoACobrar}}.-</p>
        </div>
        <div class="col"></div>
        <div class="col">
          <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Total pagado CPT:</span> Bs.
            {{importeTotalCPT}}</p>
          <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Total pagado boletas:</span> Bs.
            {{importeTotalComprobantes}}.-</p>
        </div>
      </div>

      <form [formGroup]="tiposPagoForm">
        <div class="first-step">
          <div class="step-contain">

            <mat-radio-group aria-label="Selecciona un tipo de pago" formControlName="tipoPago">

              <div class="tipo-de-pago" [ngClass]="{ 'tipo-seleccionado': tiposPagoForm.value.tipoPago==1 }">
                <mat-radio-button value="1" color="primary">
                  <img src="assets/images/pago-cpt.jpg" alt="Pago por PPTE">
                  Paga usando PPTE
                </mat-radio-button>
              </div>

              <div class="tipo-de-pago" [ngClass]="{ 'tipo-seleccionado': tiposPagoForm.value.tipoPago==2 }">
                <mat-radio-button value="2" color="primary">
                  <img src="assets/images/transferencias.jpg" alt="Pago con deposito o transferencia">
                  Paga con Deposito o Transferencia
                </mat-radio-button>
              </div>

            </mat-radio-group>

          </div>
        </div>

        <div style=" flex-grow: 1; text-align: right;">
          <button mat-flat-button mat-dialog-close class="mx-5" (click)="cerrar()">Cancelar</button>
          <button mat-flat-button color="primary" matStepperNext (click)="stepOneNext()">Siguiente</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="comprobantesForm" label="Comprobantes de pago">
      <div class="mt-4 mb-5 text-center">
        <h2>{{ montoACobrar == 0 ? 'Su trámite no tiene ningún costo' : 'Registra tus comprobantes de pago'}}</h2>
      </div>

      <div *ngIf="comprobantesCPT.length > 0">

        <div class="" style="display: flex;justify-content: space-between;">
          <div class="d-flex align-items-end">
            <p style="font-weight: 300; font-size: 1rem;">Comprobantes de pago por PPTE</p>
          </div>
        </div>

        <table>
          <tbody>
            <tr *ngFor="let comprobanteCPT of comprobantesCPT">
              <div class="comprobante-row">
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Numero de documento</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.numero_documento" readonly disabled
                      class="input-disabled">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Codigo CPT</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.cpt" readonly disabled class="input-disabled">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Fecha</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.fecha_alta" readonly disabled
                      class="input-disabled">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Importe Bs.</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.importe - 1" readonly disabled
                      class="input-disabled">
                  </mat-form-field>
                </td>

                <td></td>
              </div>
            </tr>
          </tbody>
        </table>

        <div class="row mt-2 mb-5 justify-content-end">
          <div class="col fw-semibold" style="display:inline-flex;align-items: center;justify-content: end;">
            <span>&nbsp;Importe total CPT: <span class="fw-bold">Bs. {{ importeTotalCPT }}.-</span></span>
          </div>
        </div>
      </div>

      <div [formGroup]="comprobantesForm">
        <div class="my-3" style="display: flex;justify-content: space-between;"
          *ngIf="comprobantesBancarios.value.length > 0">
          <div class="d-flex align-items-end">
            <p style="font-weight: 300; font-size: 1rem;">Comprobantes de pago por boleta</p>
          </div>

          <div class="d-flex align-items-center">
            <button mat-stroked-button color="primary-outline" (click)="agregarComprobante()"
              matTooltip="Agregar comprobante">
              <mat-icon>add_card</mat-icon>
              &nbsp;Agregar comprobante
            </button>
          </div>
        </div>

        <div *ngIf="comprobantesBancarios.value.length > 0; then nonEmptyBlock else emptyBlock"></div>

        <ng-template #nonEmptyBlock>
          <div class="table-responsive">
            <table>
              <tbody formArrayName="comprobantes"
                *ngFor="let comprobanteForm of comprobantesBancarios.controls; let i=index;">
                <tr [formGroupName]="i">
                  <div class="comprobante-row"
                    [ngClass]="{'registro-eliminado': comprobanteForm.value.accion == 'eliminar'}">
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Entidad bancaria</mat-label>
                        <mat-select formControlName="banco">
                          <mat-option [value]="banco.idBanco" *ngFor="let banco of bancos">{{banco.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Tipo de transaccion</mat-label>
                        <mat-select formControlName="tipoTransaccion"
                          (selectionChange)="enableCuenta($event, comprobanteForm)">
                          <mat-option [value]="tipoComprobante.idTipo" *ngFor="let tipoComprobante of tipoComprobantes">
                            {{tipoComprobante.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Cuenta</mat-label>
                        <input matInput type="text" formControlName="cuenta">
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Nro. comprobante</mat-label>
                        <input matInput type="number" formControlName="comprobante" min="0">
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Importe Bs.</mat-label>
                        <input matInput type="number" min="0" formControlName="importe"
                          (change)="verificarImporteTotal()">
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Fecha</mat-label>
                        <input matInput type="date" formControlName="fecha">
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Glosa</mat-label>
                        <input matInput class="text-uppercase" type="text" formControlName="glosa">
                      </mat-form-field>
                    </td>
                    <td>
                      <div *ngIf="comprobanteForm.value.accion!=accionEliminar">

                        <!-- <div class="icon-block">
                        <button mat-icon-button
                          [ngClass]="comprobanteForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                          (click)="adjuntarDocumento(comprobanteForm, j)" matTooltip="{{ comprobanteForm.value.base64 == null ? 'Adjuntar comprobante y guardar' : 'Cambiar
                          comprobante' }}">
                          <mat-icon style="font-size:20px;">{{ comprobanteForm.value.base64 == null ?
                            'attach_file' : 'attach_file'}}</mat-icon>
                        </button>
                      </div> -->


                        <!-- <div class="icon-block">
                        <label class="file-icon" matTooltip="Eliminar documento"
                          (click)="eliminarDocumento(docAdicionalForm, j)">
                          <mat-icon class="delete-icon">delete_outline</mat-icon>
                        </label>
                      </div> -->

                        <!-- <div class="icon-block"> -->
                        <!-- <label for="attachedFile{{i}}"
                          [ngClass]="comprobanteForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                          matTooltip="Adjuntar comprobante" *ngIf="!comprobanteForm.value.base64">
                          <mat-icon >attach_file</mat-icon>
                        </label> -->
                        <!-- </div> -->
                        <!-- <label for="attachedFile{{i}}" class="file-icon" matTooltip="Adjuntar comprobante"
                        *ngIf="!comprobanteForm.value.base64">
                        <mat-icon style="color: #34b0a6">attach_file</mat-icon>
                      </label> -->

                        <div>
                          <label for="attachedFile{{i}}" class="file-icon"
                            [ngClass]="comprobanteForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                            matTooltip="{{ comprobanteForm.value.base64 ? 'Cambiar comprobante' : 'Adjuntar comprobante' }}">
                            <mat-icon>attach_file</mat-icon>
                          </label>
                          <!-- <small style="font-size:10px;">Cargado</small> -->
                        </div>

                        <!-- <div class="icon-block"> -->
                        <button mat-icon-button color="primary" (click)="verDocumento(comprobanteForm)"
                          matTooltip="Ver comprobante" class="file-icon"
                          [disabled]="comprobanteForm.value.base64 == null">
                          <mat-icon>visibility</mat-icon>
                        </button>
                        <!-- </div> -->

                        <button mat-icon-button color="warn" class="file-icon"
                          (click)="eliminarComprobante(i, comprobanteForm)" matTooltip="Eliminar comprobante">
                          <mat-icon>delete_outline</mat-icon>
                        </button>

                        <input type="file" name="attachedFile{{i}}" id="attachedFile{{i}}"
                          (change)="adjuntarArchivo($event, comprobanteForm)" style="display: none;" accept=".pdf">

                      </div>
                      <div style="display: flex; align-items: center;"
                        *ngIf="comprobanteForm.value.accion==accionEliminar">
                        <button mat-icon-button color="warn" (click)="cancelarEliminacion(comprobanteForm)"
                          matTooltip="Cancelar eliminacion de comprobante">
                          <mat-icon>library_add_check</mat-icon>
                        </button>
                      </div>
                    </td>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row my-3 justify-content-end">
            <div class="col fw-semibold" style="display:inline-flex;align-items: center;justify-content: end;">
              <span>&nbsp;Importe total comprobantes: <span class="fw-bold">Bs.
                  {{importeTotalComprobantes}}.-</span></span>
            </div>
          </div>
        </ng-template>

        <ng-template #emptyBlock>
          <div style="display:flex; flex-direction:column; align-items:center; justify-content:center; height:225px; margin-top: 5rem;">
            <img [src]="montoACobrar == 0 ? 'assets/images/no-cost.png' : 'assets/images/empty-vouchers.png'" alt="Ningun comprobante de pago cargado">
            <span style="font-size:11px;color:#838383; margin-top:1rem;">
              {{ montoACobrar == 0 ? 'Presione el boton "Continuar" para terminar' : 'Aun no ha agregado ningun
              comprobante de pago' }}
            </span>
            <button mat-stroked-button color="primary-outline" class="my-3" aria-label="Agregar comprobante"
              *ngIf="!importeDBEstaCompleto" (click)="agregarComprobante()">
              <mat-icon>add_photo_alternate</mat-icon>
              &nbsp;Agregar comprobante
            </button>

            <button mat-flat-button color="primary" class="my-3" matStepperNext (click)="guardarComprobantes()"
              *ngIf="importeDBEstaCompleto">
              {{ montoACobrar == 0 ? 'Continuar' : textoBoton }}
            </button>

            <button mat-flat-button matStepperPrevious class="mx-5" *ngIf="!importeDBEstaCompleto">Atras</button>
            <button mat-flat-button mat-dialog-close class="mx-5" (click)="cerrar()"
              *ngIf="importeDBEstaCompleto">Cancelar</button>
          </div>
        </ng-template>

        <div class="row mt-5 mb-3 justify-content-between" *ngIf="comprobantesBancarios.value.length > 0">
          <!-- <div class="row mt-5 mb-3 justify-content-between" *ngIf="importeTotal > 0"> -->
          <div class="col fs-5">Costo del tramite: <span class="fw-bold">Bs. {{montoACobrar}}.-</span></div>
          <div class="col fs-5" style="display:inline-flex;align-items: center;justify-content: end;">
            <mat-icon style="color:#28e075;" *ngIf="importeEstaCompleto">verified</mat-icon>
            <span>&nbsp;Importe total: <span class="fw-bold">Bs. {{importeTotal}}.-</span></span>
          </div>
        </div>

        <div class="mt-5" style="text-align: right;" *ngIf="comprobantesBancarios.value.length > 0">
          <button mat-flat-button matStepperPrevious class="mx-5" *ngIf="!importeDBEstaCompleto">Atras</button>
          <button mat-flat-button mat-dialog-close class="mx-5" (click)="cerrar()"
            *ngIf="importeDBEstaCompleto">Cancelar</button>
          <button mat-flat-button color="primary" matStepperNext (click)="guardarComprobantes()"
            [disabled]="comprobantesForm.invalid || comprobantesBancarios.value.length == 0">{{textoBoton}}</button>
        </div>
      </div>
    </mat-step>

  </mat-horizontal-stepper>

  <div class="pago" [ngClass]="importeEstaCompleto ? 'pago-completo' : 'pago-incompleto'">
    <span>{{ importeEstaCompleto ? (montoACobrar == 0 ? 'TRAMITE SIN COSTO' : 'PAGO COMPLETO') : 'PAGO INCOMPLETO'
      }}</span>
  </div>

</div>