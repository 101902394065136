<div class="modal-container">

  <form [formGroup]="formDocumentos">

    <div class="row modal__header">
      <div class="col">
        <div class="modal__title">Documentos obligatorios</div>
        <div class="modal__subtitle">Debe adjuntar los documentos que se detallan a continuacion:</div>
      </div>
    </div>

    <div class="my-3">
      <table class="table table-list">
        <tbody formArrayName="documentos_obligatorios"
          *ngFor="let docObligatorioForm of documentosObligatorios.controls; let i=index">
          <tr [formGroupName]="i">
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Nombre del documento</mat-label>
                <!-- <input matInput type="text" placeholder="Descripción" disabled
                  value="{{ docObligatorioForm.value.descripcion }}"> -->
                <textarea matInput placeholder="Nombre del documento" class="description-textarea text-uppercase" disabled
                  title="{{ docObligatorioForm.value.descripcion }}">{{ docObligatorioForm.value.descripcion }}</textarea>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="input-date">
                <mat-label>No. de identificador</mat-label>
                <input matInput type="text" placeholder="Identificador" formControlName="identificador"
                  autocomplete="off">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="input-date">
                <mat-label>Fecha de emisión</mat-label>
                <input matInput type="date" placeholder="" formControlName="fecha_emision">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="input-date">
                <mat-label>Fecha fin</mat-label>
                <input matInput type="date" placeholder="" formControlName="fecha_fin">
              </mat-form-field>
            </td>

            <!-- <td>
              <button mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Adjuntar archivo" class="bordered"
                [ngClass]="docObligatorioForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'">
                <mat-icon style="font-size:20px;">{{ docObligatorioForm.value.base64 == null ?
                  'attach_file' : 'attach_file'}}</mat-icon>
                <span style="font-size: 13px">{{ docObligatorioForm.value.base64 == null ? 'Adjuntar archivo' : 'Cambiar
                  archivo'}}</span>
              </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="seleccionarNuevoDocumento(docObligatorioForm, i)">
                  <mat-icon>attach_file</mat-icon>
                  <span>Seleccionar nuevo archivo</span>
                </button>
                <button mat-menu-item (click)="adjuntarDocumento(docObligatorioForm, i)">
                  <mat-icon>content_paste_search</mat-icon>
                  <span>Buscar archivo</span>
                </button>
              </mat-menu>
            </td> -->

            <td>
              <div class="file-name">

                <div class="icon-block">
                  
                  <button mat-icon-button
                    [ngClass]="docObligatorioForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                    (click)="adjuntarDocumento(docObligatorioForm, i)" 
                    matTooltip="{{ docObligatorioForm.value.base64 == null ? 'Adjuntar archivo y guardar' : 'Cambiar archivo' }}">
                    <mat-icon style="font-size:20px;">{{ docObligatorioForm.value.base64 == null ? 'attach_file' : 'attach_file' }}</mat-icon>
                  </button>
                </div>

                <div class="icon-block">
                  <button mat-icon-button (click)="verDocumento(docObligatorioForm)" matTooltip="Ver documento"
                    [disabled]="docObligatorioForm.value.base64 == null">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>

                <!-- <div class="icon-block">
                  <button mat-icon-button (click)="guardarDocumento(docObligatorioForm)" matTooltip="Guardar documento">
                    <mat-icon>save</mat-icon>
                  </button>
                </div> -->

                <input type="file" name="{{ DOCUMENTO_OBLIGATORIO + '-' + i }}"
                  id="{{ DOCUMENTO_OBLIGATORIO + '-' + i }}" (change)="adjuntarArchivo($event, docObligatorioForm);"
                  style="display: none;" accept=".pdf">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row modal__header">
      <div class="col">
        <div class="modal__title">Documentos adicionales</div>
        <div class="modal__subtitle">Puede adjuntar los documentos que considere necesarios</div>
      </div>
      <div class="col text-end">
        <button mat-stroked-button color="primary-outline" class="my-3" aria-label="Agregar documento adicional"
          (click)="agregarDocumento()">
          <mat-icon>add_task</mat-icon>
          &nbsp;Agregar documento
        </button>
      </div>
    </div>

    <div class="my-3">
      <table class="table table-list">
        <tbody formArrayName="documentos_adicionales"
          *ngFor="let docAdicionalForm of documentosAdicionales.controls; let j=index">
          <tr [formGroupName]="j">
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Nombre del documento</mat-label>
                <input matInput type="text" class="text-uppercase" formControlName="descripcion">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="input-date">
                <mat-label>No. de identificador</mat-label>
                <input matInput type="text" placeholder="Identificador" formControlName="identificador"
                  autocomplete="off">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="input-date">
                <mat-label>Fecha de emisión</mat-label>
                <input matInput type="date" placeholder="" formControlName="fecha_emision">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="input-date">
                <mat-label>Fecha fin</mat-label>
                <input matInput type="date" placeholder="" formControlName="fecha_fin">
              </mat-form-field>
            </td>

            <!-- <td>
              <button mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Adjuntar archivo" class="bordered"
                [ngClass]="docAdicionalForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'">
                <mat-icon style="font-size:20px;">{{ docAdicionalForm.value.base64 == null ?
                  'attach_file' : 'attach_file'}}</mat-icon>
                <span style="font-size: 13px">{{ docAdicionalForm.value.base64 == null ? 'Adjuntar archivo' : 'Cambiar
                  archivo'}}</span>
              </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="seleccionarNuevoDocumento(docAdicionalForm, j)">
                  <mat-icon>attach_file</mat-icon>
                  <span>Seleccionar nuevo archivo</span>
                </button>
                <button mat-menu-item (click)="adjuntarDocumento(docAdicionalForm, j)">
                  <mat-icon>content_paste_search</mat-icon>
                  <span>Buscar archivo</span>
                </button>
              </mat-menu>
            </td> -->

            <td>
              <div class="file-name">

                <div class="icon-block">
                  <button mat-icon-button
                    [ngClass]="docAdicionalForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                    (click)="adjuntarDocumento(docAdicionalForm, j)" matTooltip="{{ docAdicionalForm.value.base64 == null ? 'Adjuntar archivo y guardar' : 'Cambiar archivo' }}">
                    <mat-icon style="font-size:20px;">{{ docAdicionalForm.value.base64 == null ?
                      'attach_file' : 'attach_file'}}</mat-icon>
                  </button>
                </div>

                <div class="icon-block">
                  <button mat-icon-button (click)="verDocumento(docAdicionalForm)" matTooltip="Ver documento"
                    [disabled]="docAdicionalForm.value.base64 == null">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>

                <!-- <div class="icon-block">
                  <button mat-icon-button (click)="guardarDocumento(docAdicionalForm)" matTooltip="Guardar documento">
                    <mat-icon>save</mat-icon>
                  </button>
                </div> -->

                <div class="icon-block">
                  <label class="file-icon" matTooltip="Eliminar documento"
                    (click)="eliminarDocumento(docAdicionalForm, j)">
                    <mat-icon class="delete-icon">delete_outline</mat-icon>
                  </label>
                </div>

                <input type="file" name="{{ DOCUMENTO_ADICIONAL + '-' + j }}" id="{{ DOCUMENTO_ADICIONAL + '-' + j }}"
                  (change)="adjuntarArchivo($event, docAdicionalForm)" style="display: none;" accept=".pdf">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row mt-5 mb-2">
      <div class="col text-end">
        <button mat-flat-button class="mx-3" [mat-dialog-close]="false">Cancelar</button>
        <button mat-raised-button color="primary" (click)="finalizar()" class="button button-primary"
          [disabled]="enableSave">
          Aceptar y terminar
        </button>
      </div>
    </div>

  </form>

</div>