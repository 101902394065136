import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";

const base_url_sistema = environment.base_url_sistema;

@Injectable({
  providedIn: "root",
})
export class ParametroService {

  constructor(
    private http: HttpClient,
  ) { }

  //PARAMETROS 
  getPaises() {
    return this.http.get(`${base_url_sistema}/parametro/paises`);
  }
  getMunicipios() {
    return this.http.get(`${base_url_sistema}/parametro/municipios`);
  }
  getOficinas() {
    return this.http.get(`${base_url_sistema}/parametro/oficinas`);
  }
  getOficinasCentinela() {
    return this.http.get(`${base_url_sistema}/parametro/oficinas-centinela`);
  }
  getRubros() {
    return this.http.get(`${base_url_sistema}/parametro/rubros`);
  }
  getTiposSociedad() {
    return this.http.get(`${base_url_sistema}/parametro/tipos-sociedad`);
  }
  getTiposIngreso() {
    return this.http.get(`${base_url_sistema}/parametro/tipos-ingreso`);
  }
  getTiposInspeccion() {
    return this.http.get(`${base_url_sistema}/parametro/tipos-inspeccion`);
  }
  getBancos() {
    return this.http.get(`${base_url_sistema}/parametro/bancos`);
  }
  getTiposComprobantes() {
    return this.http.get(`${base_url_sistema}/parametro/tipos-comprobante`);
  }
  getSubTiposMovimiento() {
    return this.http.get(`${base_url_sistema}/parametro/sub-tipos-movimiento`);
  }

}
