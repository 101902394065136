import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public message$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() { }
  //public urlSocket = environment.base_url_backend == 'https://edapi.mingobierno.gob.bo' ? 'wss://edapi.mingobierno.gob.bo' : 'wss://api.ed6.dgsc.gob.bo';
  //public urlSocket = environment.base_url_backend == 'https://edapi.mingobierno.gob.bo/dgsc_api' ? 'wss://edapi.mingobierno.gob.bo' : 'wss://sistema1.dgsc.gob.bo';
  public urlSocket = environment.base_url_backend == 'https://edapi.mingobierno.gob.bo/dgsc_api' ? 'wss://edapi.mingobierno.gob.bo' : 'wss://api.sistema0.dgsc.gob.bo';
  socket = io(this.urlSocket,
    // socket = io('http://165.172.16.36:8000',
    {
      transports: ["websocket", "polling"],
      path: '/socketdgsc',
      withCredentials: false,
      extraHeaders: {
        "my-custom-header": "dgsc"
      }
    }
  );

  //socket = io('https://edapi.mingobierno.gob.bo/dgsc_api',{path:'/socketdgsc'})
  public sendMessage(message: any) {
    // console.log('sendMessage: ', message)
    this.socket.emit('message', message)
  }

  public getNewMessage = () => {
    return new Observable((subs) => {
      this.socket.on('message', (message) => {
        //console.log('message lo que llega desde el admin: ', message)
        subs.next(message)
      })

      this.socket.on("connect", () => {
        console.log('socket.id',this.socket.id); 
      });
/*       this.socket.on("disconnect", () => {
        console.log('disconnect socket.id',this.socket.id); 
      });

      this.socket.on("reconnect", () => {
        console.log('reconnect',this.socket.id); 
      }); */

    })
  }

  /*   public getTokenAppNotification = () => {
      this.socket.on('token-app:notification', (message) => {
        this.message$.next(message)
      });
  
      return this.message$.asObservable()
    } */
  public getTokenAppNotification = () => {
    return new Observable((subs) => {
      this.socket.on('token-app:notification', (message) => {
        //this.socket.on('message',(message)=>{
        //console.log('token llega: ', message)
        subs.next(message)
      })
    })
  }

}
