<div class="auth-container">
    <div class="row justify-content-center align-items-center">
        <div class="col-md-3 auth-container__box">

            <div class="auth-container__head">
                <div class="auth-container__head head__logo">
                    <img src="assets/images/logo2021.jpg" alt="DGSC" />
                </div>
                <a routerLink="/login">
                    <img src="assets/images/iconofinal.png" alt="DGSC" width="65" height="65" />
                </a><br>
                    <mat-card-title style="text-align: center; font-size: 20px;margin-bottom: 1rem;"><br>{{authTitle}}</mat-card-title>
                    <mat-card-title style="text-align: center;  font-size: 1.3vh;"><br>{{authSubtitle}}</mat-card-title>    
                      <mat-card-title style="text-align: center; font-size: 20px;margin-bottom: 1rem;" *ngIf="dgsc"><br>ENTORNO DE
                        PRUEBAS DGSC</mat-card-title>                                  
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>