import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/base-http.service';


@Injectable({
  providedIn: 'root',
})

export class RegistroTramiteService {
  localUrl = 'registros/tramiteRegistro'
  localUrlUnico = 'registros/tramiteRegistroUnico'
  listaDocumentos = []


  constructor(private baseHttpService: BaseHttpService) { }

  public obtenerPdf(data: any): Observable<any> {
    //return this.baseHttpService.post(`${this.localUrl}/pdf-registro`, data);
    return this.baseHttpService.post(`${this.localUrl}/nuevo/registro/pdf`, data)
  }

  //servicio de obtener lista registro unico
  public tramiteRegistroUnico(): Observable<any> {
    return this.baseHttpService.get(`${this.localUrlUnico}/tramite-lista-registro-unico`);
  }

  public obtenerDatosInspeccion(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/datos-inspeccion`, data)
  }

  //cervicios tramite registro unico
  public certificadoRegistroUnicoPdf(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrlUnico}/certificado-registro-unico-pdf-df`, data)
  }

  public tramiteRegistroUnicoAprobado(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrlUnico}/tramite-registro-unico-aprobado`, data)
  }

  // obtener categoria
  public calcularCategoria(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/calcular-categoria-registro`, data)
  }

  //calcular-costo-categoria
  public costocategoriaRegistro(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/calcular-costo-categoria`, data)
  }

  //calcular-nueva-categoria
  public nuevaCategoriaRegistro(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/calcular-nueva-categoria`, data)
  }

  /**guardar categoria*/
  public guardarCategoria(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/guardar-categoria-registro`, data)
  }

  /**verificamos imagenes del nuevo registro */
  public verImagenesRegistro(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/imagenes-registro`, data)
  }

  /**verificamos imagenes del nuevo registro */
  public verImagenRegistro(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/imagen-registro`, data)
  }

  public aprobarRequisitosRevisor(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/aprobar-requisitos`, data)
  }

  public ValidarFechasSentinela(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/validar-certififcado-centinela`, data)
  }
  


}