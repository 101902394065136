<hr size="5px" style="color: #34aac7b0;border-radius: 20px;">
<mat-card-content>
   
    <div class="row">
   
        <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%;" class="header">
                <mat-label>Servidor Público</mat-label>
                <input matInput type="text" value="{{this._usuarioService.usuario.nombre}}" readonly>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline" style="width: 100%;" class="header">
                <mat-label>Cargo</mat-label>
                <input matInput type="text" value="{{this._usuarioService.usuario.rol}}" readonly>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%;" class="header">
                <mat-label>Correo</mat-label>
                <input matInput type="text" value="{{this._usuarioService.usuario.correo}}" readonly>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field appearance="outline" style="width: 100%;" class="header">
                <mat-label>Oficina</mat-label>
                <input matInput type="text" value="{{this._usuarioService.usuario.oficina}}" readonly>
            </mat-form-field>
        </div>
    </div>
</mat-card-content>
<hr size="5px" style="color: #34aac7b0;border-radius: 20px;">
<!-- <mat-card-content>
    <div mat-dialog-content>  
        <table class="table table-list-2">
            <thead>
            <tr>
                <th><strong>Número de Registro :</strong></th>
                <td>{{ this._usuarioService.usuario.num }}</td>
                <th><strong>Nombre/Razón Social :</strong></th>
                <td>{{ this._usuarioService.usuario.rz }}</td>
            </tr>
            <tr>
                <th><strong>Correo Electrónico :</strong></th>
                <td>{{ this._usuarioService.usuario.correo }}</td>
                <th><strong>Departamento :</strong></th>
                <td>{{ this._usuarioService.usuario.of }}</td>
            </tr>
            </thead>
        </table>   
    </div>
  </mat-card-content>  -->