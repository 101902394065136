import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Usuario } from '../models/usuario.model';

/**
 * Inyectable que permite la comunicacion con el backend de funciones de credenciales
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  constructor() { }

  /**
   * Get current token from session storage
   *
   * @returns {string}
   * @memberof CredentialsService
   */
  public getToken(): string {
   // return localStorage.getItem('token') || '';
    return localStorage.getItem('tokenAdmin');
  }

  /**
   * Get Logged user credentials from session storafe
   *
   * @returns {object}
   * @memberof CredentialsService
   */
   public getLoggedUser(): Usuario {
    //console.log('usuario en localstorage',localStorage.getItem('usuario'));
    return JSON.parse(localStorage.getItem('usuario'));
  }

  /**
   * Set TokenAuth Credentials to session storage
   *
   * @template T
   * @param {T} payload
   * @memberof CredentialsService
   */
  public setTokenAuth<T>(payload: string): void {
    //localStorage.setItem('token', payload.toString());
    localStorage.setItem('tokenAdmin', payload.toString());
  }

  /**
   * Set User Credentials to session storage
   *
   * @template T
   * @param {T} payload
   * @memberof CredentialsService
   */
  public setUserAuth<T>(payload: T,menus:any,img:any): void {
    localStorage.setItem('usuario', JSON.stringify(payload));
    localStorage.setItem('menus', JSON.stringify(menus));
    localStorage.setItem('img', img);
  }

  public setUserPermissions<T>(payload: T): void {
    localStorage.setItem('permissions', JSON.stringify(payload));
  }

  public getUserPermissions(): object {
    return JSON.parse(localStorage.getItem('permissions'));
  }

  /**
   * Remove User Credentials from session storage
   *
   * @return void
   * @memberof CredentialsService
   */
  public removeCredentials(): void {
    //localStorage.removeItem('token');
    localStorage.removeItem('tokenAdmin');
    localStorage.removeItem('usuario');
    localStorage.removeItem('img');
    localStorage.removeItem('menus');
  }
}
