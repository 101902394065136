import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { VerificarForm } from '../models/verificar-form.interface';
import { LoginForm } from '../models/login-form.model';
import { Usuario } from '../models/usuario.model';
import { BaseHttpService } from '../../../core/services/base-http.service';
import { CredentialsService } from './credentials.service';

const base_url = environment.base_url;
const base_url_sistema = environment.base_url_sistema;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public auth2: any;
  public usuario: Usuario;
  public foto: string;
  public menus: any[];
  private SECRET_KEY = `qYjwKyHLyG5WnUBh4SeA+PRBMpjLLV+z+z7/`;

  constructor(
    private router: Router,
    private baseHttpService: BaseHttpService,
    private credentialsService: CredentialsService,
    private http: HttpClient,
  ) {
   // console.log('Servicio de usuario listo');
    this.foto=localStorage.getItem('img') || '';
    var auxArray = localStorage.getItem('menus');
    // Se parsea para poder ser usado en js con JSON.parse :)
    this.menus = JSON.parse(auxArray);
    //console.log(this.menus)
    //this.foto=localStorage.getItem('menus') || '';
    this.usuario=credentialsService.getLoggedUser();
  }

  getSecretKey(): string {
    return this.SECRET_KEY || '';
  }
  get token(): string {
    return localStorage.getItem('tokenAdmin');
    //return localStorage.getItem('token') || '';
  }

  get uid(): string {
    return this.usuario.id || '';
  }

  get headers() {
    return { headers: { 'x-token': this.token } }
  }

  logout() {
    this.limpiarSesion() 
    this.router.navigateByUrl('/login');
     
  }

  limpiarSesion() {
    this.credentialsService.removeCredentials()
    this.usuario = null;
    this.menus = null;
    this.foto = null;
  }

  validarToken(): Observable<boolean> {
   // console.log('validando token')
    return this.baseHttpService.get("auth/renovar-token").pipe(
      map((resp: any) => {
      //  console.log('actualizando token')
        this.credentialsService.setTokenAuth(resp.datos.tokenAdmin);
        //this.credentialsService.setUserAuth(resp.datos.usuario,this.menus,this.foto);
        //this.usuario = resp.datos.usuario;
        return true; 
      }),
      catchError((error) => of(false))
    );
  }  

  loginDGSC(formData: any) {
    return this.http.post(`${base_url}/auth/login-dgsc`, formData)
      .pipe(
        tap((resp: any) => {
          console.log(resp.datos)
          this.credentialsService.setTokenAuth(resp.datos.tokenAdmin);
          this.credentialsService.setUserAuth(resp.datos.usuario,resp.datos.menus,resp.datos.img);
          this.usuario = resp.datos.usuario;
          this.menus = resp.datos.menus;
          this.foto = resp.datos.img;
        })
      );
  }


  // PERSONA 
  registrarPersona(persona: any) {
    return this.baseHttpService.post("persona/", persona);
  }

  //USUARIO 
  registrarUsuario(usuario: any) {
    return this.baseHttpService.post("usuario/", usuario);
  }

/*   activarUsuarioNuevo(codigo: string,idUsuario:number) {
    let formData = { codigo: codigo, idUsuario: idUsuario };
    return this.baseHttpService.post("usuario/activar-usuario", formData);
  }   */

  activarUsuarioNuevo(data: any) {
    // let formData = { codigo: codigo, idUsuario: idUsuario };
    return this.baseHttpService.post("usuario/activar-usuario", data);
  }


  //Usuario que no recuerda su clave puede solicitar restaurarlo
  solicitarRestablecerClave(correo: string) {
    let formData = { correo: correo };
    return this.baseHttpService.post("usuario/solicitar-restablecer-password", formData);
  }

  // Si tiene el codigo correcto->puede cambiar su clave aunq no recuerde el anterior
  restablecerClave(formData: any) {
    return this.baseHttpService.post("usuario/restablecer-password", formData);
  }

  //Usuario autentificado puede cambiar su clave actual por uno nuevo
  cambiarClaveUsuario(formData: any): Observable<any> {
    return this.baseHttpService.post("usuario/cambiar-password", formData);
  }  

  getMisRoles(): Observable<any>  {
    return this.baseHttpService.get("usuario/mis-roles");
  }

  seleccionarRol(idRol: string) {
    let formData = { idRol: idRol };

    return this.baseHttpService.post("usuario/seleccionar-rol", formData)
    .pipe(
      tap((resp: any) => {
        console.log(resp.datos.usuario)
        this.credentialsService.setTokenAuth(resp.datos.tokenAdmin);
        this.credentialsService.setUserAuth(resp.datos.usuario,resp.datos.menus,this.foto);
        this.usuario = resp.datos.usuario;
        this.menus = resp.datos.menus;
      })
    );
  }

  getPaises() {
    return this.http.get(`${base_url_sistema}/parametro/paises`);
  }

  existeToken(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        reset: token,
      }),
    };
    return this.http.put(`${base_url}/login/existeToken`, null, httpOptions);
  }

  verificar(formData: VerificarForm) {
    return this.http.post(`${base_url}/loginf/verifica`, formData);
  }

  actualizarPerfil(data: { email: string, nombre: string, role: string }) {
    return this.http.put(`${base_url}/usuariosf/${this.uid}`, data, this.headers);
  }

  guardarPersona(persona: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.token,
      })
    }
    return this.http.post(`${base_url}/personaf`, persona, httpOptions);
  }

  getRegistro(numRegistro: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.token,
      })
    }
    return this.http.post(`${base_url}/registrof/` + numRegistro, null, httpOptions);
  }

  resetPassword(formData: any): Observable<any> {
    return this.baseHttpService.post('loginf/reset-password', formData);
  }

  getRolesFuncionario(idUsuario: number): Observable<any> {
    return this.baseHttpService.get('admin/roles-funcionario/'+idUsuario);
  }
  /**get oficina pora asignar al funcionario */
  getOficinaFuncionario(idUsuario: number): Observable<any> {
    return this.baseHttpService.get('admin/oficinas-funcionario/');
  }
  getOficinaXFuncionario(idUsuario: number): Observable<any> {
    return this.baseHttpService.get('admin/oficinasXfuncionario/'+idUsuario);
  }
  guardarRolesFuncionario(formData: any): Observable<any> {
    return this.baseHttpService.post('admin/roles-funcionario', formData);
  }
  pdfFD_AsignacionRoles(formData: any): Observable<any> {
    return this.baseHttpService.post('tramite/roles-funcionario/pdf-fd', formData);
  }
  getMenusRol(idRol: number): Observable<any> {
    return this.baseHttpService.get('admin/menus-rol/'+idRol);
  }
  guardarMenusRol(formData: any): Observable<any> {
    return this.baseHttpService.post('admin/menus-rol', formData);
  }
  pdfFD_AsignacionMenus(formData: any): Observable<any> {
    return this.baseHttpService.post('tramite/menus-rol/pdf-fd', formData);
  }
  
  /**** */
  /**
   * Método que actualiza la asignacion aleatoria de atencion a tramites pendientes
   */ 
public asignarTramite(data:any): Observable<any> {
  return this.baseHttpService.post(`admin/asignar-tramite`,data);
}
public pendientesPorAsignarTramite(data:any): Observable<any> {
  return this.baseHttpService.post(`admin/pendientes-tramite`,data);
}
public obtenerTodos(data:any): Observable<any> {    
  return this.baseHttpService.post(`admin/obtenertodos`,data);
}
public notificaciones(): Observable<any> {    
  return this.baseHttpService.get(`admin/notificaciones`);
}
/**reasignar tramite */
public reasignarTramite(data: any): Observable<any> {    
  return this.baseHttpService.post(`admin/reasignarTramite`,data);
}
public obtenerTramitesAsigxUsuario(): Observable<any> {    
  return this.baseHttpService.get(`admin/obtenerTramitesAsigxUsuario`)

  /** */
}
}
