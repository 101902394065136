import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class FirmaDigitalService {

  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  // JACOBITUS TOTAL
  getEstado(): Promise<any> {
    return this.baseHttpService.getFD('api/token/status').toPromise();
  }

  getTokens(): Promise<any> {
    return this.baseHttpService.getFD('api/token/connected').toPromise();
  }

  getTokenData(slot, pin): Observable<any> {
    let parametros = { "slot": slot, "pin": pin }
    return this.baseHttpService.postFD('api/token/data', parametros);
  }

  firmarPDF(slot, pin, alias, pdf64): Observable<any> {
    const parametros = { slot: slot, pin: pin, alias: alias, pdf: pdf64 };
    return this.baseHttpService.postFD("api/token/firmar_pdf", parametros);
  }

}
