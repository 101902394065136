import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs: any;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  redirectTo(route) {
    this.router.navigateByUrl(route);
  }

}
