import { lastValueFrom } from 'rxjs'
import { ParametroService } from 'src/app/core/services/parametros.service'
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import Swal from 'sweetalert2'
import { MatStepper } from '@angular/material/stepper'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { PagosService } from 'src/app/core/services/pagos.service'
import { Parametricas } from 'src/app/core/enums/parametricas.enums'
import { VisorPdfDialogoComponent } from '../visor-pdf-dialogo/visor-pdf-dialogo.component'

@Component({
  selector: 'app-transaccion-pago',
  templateUrl: './transaccion-pago.component.html',
  styleUrls: ['./transaccion-pago.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class TransaccionPagoComponent implements OnInit, AfterViewChecked {

  idTramite: number
  @ViewChild("stepper") stepper
  secondStepComplete = false
  comprobanteEsVerificado = true
  montoACobrar: number
  tipoTramite: string

  isClicked = false
  isClickedSecondStep = false

  bancos: any[] = []
  tipoComprobantes: any[] = []
  comprobantesNoValidos: any[] = []

  importeTotalComprobantes: number = 0
  importeEstaCompleto: boolean = false

  tiposPagoForm = this.formBuilder.group({
    tipoPago: ['', Validators.required]
  })
  tiposPagoFormIsEditable: boolean = true

  comprobantesForm: FormGroup
  accionEliminar = Parametricas.ELIMINAR
  textoBoton: string = 'Guardar comprobantes'

  comprobantesPago: any[] = []
  comprobantesCPT: any[] = []

  importeTotal: number = 0
  importeTotalCPT: number = 0

  // selectedIndex: number = 0
  hidden: boolean = true

  show = true
  totalPagadoDB: number = 0
  importeDBEstaCompleto: boolean = false

  constructor(
    private pagosService: PagosService,
    private parametroService: ParametroService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogTransaccionPago: MatDialogRef<TransaccionPagoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.idTramite = data.idTramite
  }

  ngOnInit() {
    this.dialogTransaccionPago.updateSize('1020px', '600px')
    this.dialogTransaccionPago.disableClose = true
    this.comprobantesForm = this.formBuilder.group({
      comprobantes: this.formBuilder.array([])
    })

    this.obtenerCostoComprobantes()
    this.getBancos();
    this.getTipoComprobantes()
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges()
  }

  get comprobantesBancarios(): FormArray {
    return this.comprobantesForm.controls.comprobantes as FormArray
  }

  /**
   * Obtiene el costo, Info y comprobantes de un tramite desde el Backend
   * @returns {void}
   */
  async obtenerCostoComprobantes() {
    try {
      var respCostoComprobantes = await lastValueFrom(this.pagosService.obtenerCostoComprobantes({ idTramite: this.idTramite }))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    this.tipoTramite = respCostoComprobantes.datos.tramite.nombre_tipo_tramite
    this.montoACobrar = respCostoComprobantes.datos.costo

    this.comprobantesPago = respCostoComprobantes.datos.comprobantes
    this.comprobantesCPT = respCostoComprobantes.datos.comprobantesCPT

    this.importeTotalCPT = this.obtenerImporteTotalComprobantesCPT()

    const comprobantesDB = respCostoComprobantes.datos.comprobantes

    if (comprobantesDB.length > 0) {
      this.textoBoton = 'Guardar cambios'
      for (const comprobante of comprobantesDB) {
        this.agregarComprobante(comprobante)
      }
    }

    this.verificarImporteTotal()
    this.importeDBEstaCompleto = (this.importeTotal >= this.montoACobrar) ? true : false

    if (this.importeDBEstaCompleto) {
      // Enviamos al usuario a la pantalla de comprobantes
      this.tiposPagoForm.patchValue({ tipoPago: Parametricas.PAGO_POR_BOLETAS })
      this.tiposPagoFormIsEditable = false
    }

    this.hidden = false
  }

  getBancos() {
    this.parametroService.getBancos().subscribe({
      next: (response: any) => { this.bancos = response.datos.bancos },
      error: error => console.log(error)
    })
  }

  getTipoComprobantes() {
    this.parametroService.getTiposComprobantes().subscribe({
      next: (response: any) => this.tipoComprobantes = response.datos.tiposComprobante,
      error: error => console.log(error)
    })
  }

  async stepOneNext() {
    this.isClicked = true

    if (this.isClicked && !this.tiposPagoForm.valid) {
      Swal.fire({ text: 'Debe seleccionar un tipo de pago', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    if (this.tiposPagoForm.value.tipoPago == 1) {
      // Obtenemos el importe total de los comprobantes de pago
      const importeTotalComprobantes = this.obtenerImporteTotalComprobantes(this.comprobantesPago)
      // Obtenemos el importe total de los comprobantes CPT
      const importeTotalComprobantesCPT = this.obtenerImporteTotalComprobantesCPT()

      const importeTotal = importeTotalComprobantes + importeTotalComprobantesCPT

      let saldoTotal = 0
      if (this.montoACobrar >= importeTotal)
        saldoTotal = this.montoACobrar - importeTotal

      this.dialogTransaccionPago.close({
        ok: true,
        tipoPago: 'CPT',
        montoAPagar: saldoTotal + Parametricas.costoPorPagoPPTE
      })
    }
  }

  agregarComprobante(comprobante: any = null) {
    // Validamos la cuenta para deshabilitar o habilitar el input:cuenta
    var cuentaValue = comprobante && comprobante.cuenta ? comprobante.cuenta : ''
    var disabledCuenta = comprobante && comprobante.cuenta ? false : true

    const comprobanteForm = this.formBuilder.group({
      banco: [comprobante && comprobante.id_banco ? comprobante.id_banco : '', Validators.required],
      tipoTransaccion: [comprobante && comprobante.id_tipo_comprobante ? comprobante.id_tipo_comprobante : '', Validators.required],
      cuenta: [{ value: cuentaValue, disabled: disabledCuenta }, Validators.required],
      comprobante: [comprobante && comprobante.numero ? comprobante.numero : '', Validators.required],
      fecha: [comprobante && comprobante.fecha ? comprobante.fecha : '', Validators.required],
      glosa: [comprobante && comprobante.glosa ? comprobante.glosa : '', Validators.required],
      importe: [comprobante && comprobante.importe ? comprobante.importe : '', [Validators.required, Validators.min(0)]],
      base64: [comprobante && comprobante.id_documento_adicional ? 'data:application/pdf;base64,' : null, Validators.required],
      archivo: [comprobante && comprobante.archivo ? comprobante.archivo : null, null],

      nuevoArchivoAdjunto: [false, null],

      // Propiedad que indica la accion con el objeto por defecto 'CREAR'
      accion: [comprobante && comprobante.id_documento_adicional ? Parametricas.ACTUALIZAR : Parametricas.CREAR, null], // crear || actualizar || eliminar

      // Propiedades a usar para la edicion
      id_comprobante: [comprobante && comprobante.id_comprobante ? comprobante.id_comprobante : null, null],
      id_documento_adicional: [comprobante && comprobante.id_documento_adicional ? comprobante.id_documento_adicional : null, null],
      id_estado_documento_adicional: [comprobante && comprobante.id_estado_documento_adicional ? comprobante.id_estado_documento_adicional : null, null],
    })

    this.comprobantesBancarios.push(comprobanteForm)
    this.verificarImporteTotal()
  }

  enableCuenta(tipoTransaccion, comprobanteForm: FormGroup) {
    comprobanteForm.controls.cuenta.enable()

    if (tipoTransaccion.value != 2) {
      comprobanteForm.patchValue({
        cuenta: ''
      })
      comprobanteForm.controls.cuenta.disable()
    }
  }

  adjuntarArchivo(event: any, form: FormGroup): void {
    if (event.target.files.length > 0) {
      form.patchValue({
        archivo: null,
        base64: null,
        nuevoArchivoAdjunto: false
      })

      const size = event.target.files[0].size
      const fileType = event.target.files[0].type
      const fileName = event.target.files[0].name

      if (fileType !== 'application/pdf') {
        Swal.fire({ text: 'Solo se aceptan archivos en formato .pdf', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      if (size > Parametricas.maxFileSize) {
        Swal.fire({ text: `El archivo no puede tener mas de ${Parametricas.maxFileSize / 1024 / 1024}Mb de tamaño`, icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = async (ev: any) => {
        let docBase64 = ev.target.result

        //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
        // docBase64 = docBase64.substring(28)
        let archivoBinario = window.atob(docBase64.substring(28))

        // // Verificamos si el archivo esta dañado
        if (archivoBinario.lastIndexOf("%PDF-") !== 0 || archivoBinario.lastIndexOf("%%EOF") === -1) {
          Swal.fire({ text: 'El archivo esta dañado, por favor revise y vuelva a intentarlo', icon: 'warning', confirmButtonText: 'Aceptar' })
          return
        }

        form.patchValue({
          archivo: fileName,
          base64: docBase64,
          nuevoArchivoAdjunto: true
        })

        // Swal.fire({ text: 'Se adjunto un nuevo documento a su trámite', icon: 'success', confirmButtonText: 'Aceptar' })
      }
    }
  }

  async verDocumento(comprobanteForm) {

    // Si aun no se cargo ningun archivo (Significa que esta en null)
    if (!comprobanteForm.value.base64)
      return

    let docBase64 = null

    if (comprobanteForm.value.base64.length > 28) {
      docBase64 = comprobanteForm.value.base64

      // Significa que se cargo un nuevo archivo
      if (docBase64.substring(0, 28) == 'data:application/pdf;base64,') {
        //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
        docBase64 = comprobanteForm.value.base64.substring(28)
      }
    }

    // // Si ya se cargo el archivo pero aun no se trajo el archivo del backend (Modo: edicion)
    if (comprobanteForm.value.base64 == 'data:application/pdf;base64,') {
      let documentoResp = null
      try {
        documentoResp = await lastValueFrom(this.pagosService.obtenerComprobante({ idComprobante: comprobanteForm.value.id_comprobante }))
      } catch (error) {
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      comprobanteForm.patchValue({
        base64: `data:application/pdf;base64,${documentoResp.datos.comprobante.base64}`
      })

      // docBase64 = comprobanteForm.value.base64
      docBase64 = documentoResp.datos.comprobante.base64
    }

    this.dialog.open(VisorPdfDialogoComponent, {
      data: { archivoBase64: docBase64, disableCancelButton: true }
    })
  }

  verificarImporteTotal(): void {
    this.obtenerImporteTotal()
    this.importeTotal = this.importeTotalComprobantes + this.importeTotalCPT

    this.importeEstaCompleto = (this.importeTotal >= this.montoACobrar) ? true : false
  }

  obtenerImporteTotal() {
    this.importeTotalComprobantes = this.comprobantesBancarios.value.reduce((acumulado, comprobante) => {
      if (comprobante.accion != Parametricas.ELIMINAR)
        if (comprobante.importe)
          acumulado += Number(comprobante.importe)
      return acumulado
    }, 0)

    return this.importeTotalComprobantes
  }

  obtenerImporteTotalComprobantes(comprobantes) {
    let importeTotal = comprobantes.reduce((acumulado, comprobante) => {
      if (comprobante.importe)
        acumulado += Number(comprobante.importe)
      return acumulado
    }, 0)

    return importeTotal
  }

  obtenerImporteTotalComprobantesCPT() {
    // Obtenemos el importe total de los comprobantes CPT
    const importeTotalComprobantesCPT = this.obtenerImporteTotalComprobantes(this.comprobantesCPT)
    // Obtenemos el total pagado por pagos PPTE (Bs 1 por cada pago)
    const descuentoPagoCPT = this.comprobantesCPT.length * 1

    return importeTotalComprobantesCPT - descuentoPagoCPT
  }

  async guardarComprobantes() {
    this.secondStepComplete = false
    this.isClickedSecondStep = true

    if (this.comprobantesForm.invalid) {
      Swal.fire({ text: 'Revise que los datos de los comprobantes esten completos y correctos', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    if (this.montoACobrar > 0 && this.comprobantesBancarios.value.length > 0) {
      // Validamos los comprobantes en el backend
      await this.validarComprobantes()

      if (!this.comprobanteEsVerificado) {
        Swal.fire({ text: 'Comprobantes no validos', icon: 'warning', confirmButtonText: 'Aceptar' })
      }
    }

    // Guardar los comprobantes/boletas de pago
    Swal.fire({ text: 'Guardando los comprobantes aguarde por favor', icon: 'info', allowOutsideClick: false, confirmButtonText: 'Aceptar' })
    Swal.showLoading()
    try {
      var respPago = await lastValueFrom(this.pagosService.guardarComprobantes({
        comprobantes: this.comprobantesBancarios.value,
        idTramite: this.idTramite,
      }))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    let textoPago = 'Pago completo'
    let saldoAPagar = respPago.datos.saldoAPagar
    if (respPago.datos.saldoAPagar > 0) {
      textoPago = ` Aun tiene un saldo de Bs. ${saldoAPagar}`
    }

    textoPago = this.montoACobrar == 0 ? 'Tramite sin costo' : textoPago

    Swal.fire({ title: 'Guardado correctamente', text: textoPago, icon: 'success', confirmButtonText: 'Aceptar' })

    this.dialogTransaccionPago.close({
      'ok': true,
      'tipoPago': 'BANCO',
      'comprobantes': this.comprobantesBancarios.value,
    })

  }

  /**
   * Valida los comprobantes en el Backend si son correctos o si se han usado
   * 
   * @returns {boolean}
   */
  async validarComprobantes() {
    Swal.fire({ text: 'Validando comprobantes', icon: 'info', allowOutsideClick: false, confirmButtonText: 'Aceptar' })
    Swal.showLoading()
    try {
      const response = await lastValueFrom(this.pagosService.validarComprobantes(this.comprobantesBancarios.value))
      Swal.close()

      // if (!response.ok) {
      //   this.comprobantesNoValidos = response.comprobantes
      //   this.comprobanteEsVerificado = false
      // } 

      this.comprobanteEsVerificado = true
      return true
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: "error", confirmButtonText: 'Aceptar' })
      return
    }
  }

  eliminarComprobante(index: number, comprobanteForm: FormGroup) {

    if (comprobanteForm.value.accion != Parametricas.CREAR) {
      // Indicamos que este componente debe ser eliminado
      comprobanteForm.patchValue({ accion: this.accionEliminar })

      comprobanteForm.controls['banco'].disable()
      comprobanteForm.controls['tipoTransaccion'].disable()
      comprobanteForm.controls['cuenta'].disable()
      comprobanteForm.controls['comprobante'].disable()
      comprobanteForm.controls['fecha'].disable()
      comprobanteForm.controls['glosa'].disable()
      comprobanteForm.controls['importe'].disable()

    } else {
      this.comprobantesBancarios.removeAt(index)
    }
    this.verificarImporteTotal()
  }

  cancelarEliminacion(comprobanteForm: FormGroup) {

    // Indicamos que este componente ya no sera eliminado
    comprobanteForm.patchValue({ accion: Parametricas.ACTUALIZAR })

    comprobanteForm.controls['banco'].enable()
    comprobanteForm.controls['tipoTransaccion'].enable()
    if (comprobanteForm.value.tipoTransaccion != 1)
      comprobanteForm.controls['cuenta'].enable()
    comprobanteForm.controls['comprobante'].enable()
    comprobanteForm.controls['fecha'].enable()
    comprobanteForm.controls['glosa'].enable()
    comprobanteForm.controls['importe'].enable()

    this.verificarImporteTotal()
  }

  cerrar(): void {
    this.dialogTransaccionPago.close({ ok: false })
  }
}
