<h2 mat-dialog-title>{{data.tipo}}</h2>

<form (ngSubmit)="aceptar()" [formGroup]="tokenForm" autocomplete="off">
  <div mat-dialog-content *ngIf="tokens.length>1">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Seleccione el token que quiere utilizar</mat-label>

      <mat-select placeholder="Token" (selectionChange)="setToken($event.value)">
        <mat-option *ngFor="let token of tokens" [value]="token.slot">
          {{ token.slot + '-' +token.name + ' (' +token.serial+')' }}
        </mat-option>
      </mat-select>

    </mat-form-field>
  </div>

  <div mat-dialog-content>
    <div class="mensaje">Token conectado: {{model}}</div>
    <div class="mensaje">Fabricante: {{name}}</div>
  </div>

  <div mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>PIN</mat-label>
      <input matInput type="text" class="key" formControlName="pin" autocomplete="off" maxlength="10" size="10"
        cdkFocusInitial>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="justify-content-end mt-3">
    <button mat-flat-button [mat-dialog-close]="{ok: false}">Cancelar</button>
    <button mat-raised-button color="primary" type="submit">Aceptar</button>
  </div>
</form>