import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

@Component({
  selector: "app-visor-pdf-dialogo",
  templateUrl: "./visor-pdf-dialogo.component.html",
  styleUrls: ["./visor-pdf-dialogo.component.scss"],
})
export class VisorPdfDialogoComponent implements OnInit {

  defaultDialogHeight: number
  minDialogWidth: number
  maxDialogHeight: number
  minDialogHeight: number
  screenWidth: number
  screenHeight: number

  dialogWidth: number
  dialogHeight: number
  pdfViewerHeight: number

  headerDialogHeight: number
  footerDialogHeight: number

  isFullScreen: boolean

  source = ""
  base64: boolean = false
  url: boolean = false

  titulo = ""
  subtitulo = ""
  textoBoton = ""
  botonesExtra = []
  disableCancelButton: boolean = false

  tipoArchivo: string = 'pdf'
  fileName: string = ''

  constructor(
    public dialogVisorPdf: MatDialogRef<VisorPdfDialogoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    const date = new Date()
    this.fileName = this.data.fileName || 'documento-digital'
    this.fileName += `-${date.toISOString()}`

    this.defaultDialogHeight = 0.75
    this.minDialogWidth = 1080 // Size in pixels
    this.maxDialogHeight = 0.97
    this.minDialogHeight = 650 // Size in pixels
    this.headerDialogHeight = 135 // Size in pixels
    this.footerDialogHeight = 90 // Size in pixels
    this.isFullScreen = false

    this.resizeDialog(this.defaultDialogHeight)

    this.titulo = this.data && this.data.titulo ? this.data.titulo : 'Visualización del archivo'
    this.subtitulo = this.data && this.data.subtitulo ? this.data.subtitulo : ''
    this.textoBoton = this.data && this.data.textoBoton ? this.data.textoBoton : 'Aceptar'
    this.botonesExtra = this.data && this.data.botonesExtra ? this.data.botonesExtra : []

    // Propiedad para indicar que el archivo a mostrar es una imagen
    this.tipoArchivo = this.data?.tipoArchivo || 'pdf'

    if (typeof this.data.disableCancelButton != 'undefined' && this.data.disableCancelButton != null && this.data.disableCancelButton != false) {
      this.disableCancelButton = this.data.disableCancelButton
    }

    this.disableCancelButton = this.data.disableCancelButton ? true : this.data.disableCancelButton

    // TODO: VALIDAR SI ES BASE64 O ES RUTA
    if (this.data.archivoBase64) {
      this.source = this.data.archivoBase64
      this.base64 = true
    } else if (this.data.ruta) {
      this.source = this.data.ruta
      this.url = true
    }
  }

  maximizar() {
    this.resizeDialog(this.maxDialogHeight)
    this.isFullScreen = true
  }

  minimizar() {
    this.resizeDialog(this.defaultDialogHeight)
    this.isFullScreen = false
  }

  resizeDialog(newHeight: number, newWidth: number = 1) {
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight

    // newWidth = newWidth || 1 // Si no recibe ningun ancho se le asigna el ancho de la pantalla

    this.dialogWidth = this.screenWidth * newWidth
    this.dialogHeight = this.screenHeight * newHeight

    if (this.dialogHeight < this.minDialogHeight)
      this.dialogHeight = this.minDialogHeight

    if (this.dialogWidth > this.dialogHeight * 1.5)
      this.dialogWidth = this.dialogHeight * 1.5

    if (this.dialogWidth < this.minDialogWidth)
      this.dialogWidth = this.minDialogWidth

    this.pdfViewerHeight = this.dialogHeight - this.headerDialogHeight - this.footerDialogHeight

    this.dialogVisorPdf.updateSize(`${this.dialogWidth}px`, `${this.dialogHeight}px`)
  }

  aceptar() {
    this.dialogVisorPdf.close({ res: true, code: 1 }) // true: aceptar, code: 1 // ACEPTAR
  }

  cancelar() {
    this.dialogVisorPdf.close({ res: false, code: 0 }) // false: cancelar, code: 0 // Cancelar
  }

  accionesExtra(code: number) {
    this.dialogVisorPdf.close({ res: true, code: code }) // true: rechazar, code: LO DEFINE EL QUE SOLICITA 
  }
}
