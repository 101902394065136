<mat-card>
  
  <!--  <div class="row justify-content-center"> -->
   <div class="modal-container__modal">
     <div class="modal-container__head head__logo">          
       <img style="height: 6vh;" src="assets/images/logo2021.jpg" alt="DGSC"/>       
     </div><br>
     <mat-card-title style="text-align: center;" class="main__title">ESTADO DIGITAL</mat-card-title>
     <mat-card-title style="text-align: center; font-size: 1.5vh;margin-bottom: 0.1rem;">DIRECCIÓN GENERAL DE SUSTANCIAS CONTROLADAS</mat-card-title>
 
     <div class="modal-container__head" *ngIf="prueba"> 
     <button mat-flat-button color="primary" (click)="pruebaDatos(1)">Prueba1</button> &nbsp;
     <button mat-flat-button color="primary" (click)="pruebaDatos(2)">Prueba2</button> &nbsp;
     <button mat-flat-button color="primary" (click)="pruebaDatos(3)">Prueba3</button>
   </div>  
     <!-- <div class="modal-container__body" style="height: 410px;"> -->
       <mat-card-content>      
         <div class="row">
          <mat-card-subtitle class="subtitle" style="font-size: 1.5vh"><strong>REGISTRO DE DATOS PERSONALES</strong></mat-card-subtitle>
         </div>
         <form [formGroup]="registroForm" novalidate >
         
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Tipo de Documento</mat-label>
                <mat-select formControlName="tipoDocumento" required>
                  <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                    {{tipo.nombre}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf='registroForm.controls["tipoDocumento"].hasError("required")'>Campo requerido</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Número de Documento</mat-label>
                <input matInput type="text" formControlName="numeroDocumento" required  pattern="^(?!\s)(?!.*\s$).*$" (input)="validateCI($event)">
                <mat-error *ngIf='registroForm.controls["numeroDocumento"].hasError("required")'>Campo
                  requerido</mat-error>
                  <mat-error *ngIf="registroForm.get('numeroDocumento').invalid">No se permiten espacios al inicio o al final</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Con/Sin Complemento</mat-label>
                <mat-select formControlName="complementoVisible" (selectionChange)="toggleComplemento($event)" required >
                  <mat-option *ngFor="let tipo of tiposComplemento" [value]="tipo.id">{{tipo.nombre}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Complemento</mat-label>
                <input matInput type="text" formControlName="complemento" pattern="^(?!\s)(?!.*\s$).*$" (input)="validateCom($event)">
                <mat-error *ngIf="registroForm.get('complemento').invalid">No se permiten espacios al inicio o al final</mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Nombres</mat-label>
                <input matInput class="text-uppercase" type="text" name="name" formControlName="nombres"  required>
                <mat-error *ngIf="registroForm.controls['nombres'].hasError('required')">
                  Campo obligatorio
                </mat-error>
                <mat-error *ngIf="registroForm.get('nombres').invalid">No se permiten espacios al inicio o al final</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Primer apellido</mat-label>
                <input matInput class="text-uppercase" type="text" name="lastName" formControlName="primerApellido" >             
                <mat-error *ngIf="registroForm.get('primerApellido').invalid">No se permiten espacios al inicio o al final</mat-error>
                 
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline">
                <mat-label>Segundo apellido</mat-label>
                <input matInput class="text-uppercase" type="text" name="lastname" formControlName="segundoApellido"  >
                <mat-error *ngIf="registroForm.get('segundoApellido').invalid">No se permiten espacios al inicio o al final</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" style="height: 50%;">
                <mat-label>Fecha de nacimiento</mat-label>
                <input matInput type="date" formControlName="fechaNacimiento" >
                <!-- <input matInput [matDatepicker]="picker" formControlName="fechaNacimiento"
                                            name="birthDate"> -->
                <!-- <mat-error *ngIf="registroForm.controls['fechaNacimiento'].hasError('required')">
                      Campo obligatorio
                    </mat-error> -->
                <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
                <!-- <mat-datepicker #picker></mat-datepicker> -->
                <mat-error *ngIf='registroForm.controls["fechaNacimiento"].hasError("required")'>Campo
                  requerido</mat-error>
              </mat-form-field>
  
            </div>
          </div>
             <!-- 
              <mat-card-subtitle class="subtitle"><strong>DIRECCIÓN Y TELEFONO.</strong></mat-card-subtitle>-->
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>País de Nacimiento</mat-label>
                    <mat-select formControlName="paisNacimiento" (selectionChange)="seleccionarPais($event)" required>
                      <mat-option *ngFor="let pais of paises" [value]="pais.id_pais">
                        {{pais.nombre}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf='registroForm.controls["paisNacimiento"].hasError("required")'>Campo
                      requerido</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Nacionalidad</mat-label>
                    <input matInput type="text" name="nacionalidad" [value]="nacionalidadName" disabled="true">
                    <!-- <mat-select formControlName="nacionalidad">
                          <mat-option value="1">Boliviana</mat-option>
                          <mat-option value="2">Extranjero</mat-option>
                        </mat-select> -->
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Dirección</mat-label>
                    <input matInput class="text-uppercase" type="text" maxlength="50" formControlName="direccion" name="address">             
                    <mat-error *ngIf="registroForm.get('direccion').invalid">
                      No se permiten espacios al inicio o al final
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Celular</mat-label>
                    <input matInput type="tel" placeholder="Ej. 76543210" maxlength="8" formControlName="telefono"
                      name="phone" pattern="[0-9]{8}">
                    <mat-error *ngIf="registroForm.get('telefono').invalid">
                      * El número de celular no es válido.
                    </mat-error>
                    <mat-error *ngIf="registroForm.get('telefono').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <!--     <div class="col-md-3">
                      <mat-form-field appearance="outline">
                        <mat-label>Dirección</mat-label>
                        <input matInput class="text-uppercase" type="text" maxlength="50" formControlName="direccion" name="address">             
                        <mat-error *ngIf="registroForm.get('direccion').invalid">
                          No se permiten espacios al inicio o al final
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field appearance="outline">
                        <mat-label>Celular</mat-label>
                        <input matInput type="tel" placeholder="Ej. 76543210" maxlength="8" formControlName="telefono"
                          name="phone" pattern="[0-9]{8}">
                        <mat-error *ngIf="registroForm.get('telefono').invalid">
                          * El número de celular no es válido.
                        </mat-error>
                        <mat-error *ngIf="registroForm.get('telefono').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error>
                      </mat-form-field>
                    </div> -->
                    <div class="col-md-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Correo personal</mat-label>
                        <input matInput type="text" formControlName="correoPersonal" maxlength="50" name="email"  required >
                        <mat-error *ngIf="registroForm.get('correoPersonal').invalid">El correo electrónico no es válido</mat-error>
                        <mat-error *ngIf="registroForm.get('correoPersonal').hasError('pattern')">No se permiten espacios al inicio o al final</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3"></div>
                  </div>
 
           
 
         </form>
      
   
  
     <div mat-dialog-actions class="mt-3 mb-1">      
      <mat-card-actions class="d-flex justify-content-end">
         <button mat-raised-button color="primary" [disabled]="!formsAreValid()" (click)="registrar()"> <mat-icon>done</mat-icon>Registrar</button>
         <button mat-raised-button color="warn" mat-dialog-close > <mat-icon>cancel</mat-icon> Cancelar</button>
        </mat-card-actions>
     </div>
   </mat-card-content>
    </div>
 <!-- </div> -->
 
 </mat-card>