import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CredentialsService } from '../../modules/auth/services/credentials.service';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private credentialsService: CredentialsService,private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.credentialsService.getToken();
    var requestClone = request
    if (token) {
     // console.log('intercept enviando token')
      requestClone = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    return next.handle(requestClone).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.log('error autorizacion interceptado')
          this.credentialsService.removeCredentials();
          this.router.navigate(['/login']);
        }
        if (error.status === 404) {
          console.log('ruta no encontrada')
        }
        return throwError(error);
        
      })
    );
  }
}
