import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BaseHttpService } from './base-http.service'

@Injectable({
  providedIn: 'root'
})
export class DocumentosAdicionalesService {

  constructor(private baseHttpService: BaseHttpService) { }

  obtenerDocumentoAdicional(idDocumentoAdicional: number): Observable<any> {
    return this.baseHttpService.get(`tramites/documentos-adicionales/obtener-documento-adicional/${idDocumentoAdicional}`)
  }

  obtenerDocumentosAdicionales(idTramite: number): Observable<any> {
    return this.baseHttpService.get(`tramites/documentos-adicionales/${idTramite}`)
  }

  obtenerDocumentosObligatoriosYAdicionales(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/documentos-adicionales/solo-adicionales", datos)
  }

  guardarDocumentoAdicional(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/documentos-adicionales/guardar", datos)
  }

  verificarDocumento(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/documentos-adicionales/verificar", datos)
  }

  verificarDocumentosAdicionalesFirmados(idTramite: number): Observable<any> {
    return this.baseHttpService.post(`tramites/documentos-adicionales/verificar-firmados`, { idTramite })
  }

  verificarDocumentosObligatoriosFaltantes(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/documentos-adicionales/verificar-faltantes", datos)
  }

}
