import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistroUsuarioComponent } from './pages/registro-usuario/registro-usuario.component';
import { CambiarcComponent } from './pages/cambiarc/cambiarc.component';
import { LoginsisComponent } from './pages/loginsis/loginsis.component';
import { AuthLayoutComponent } from 'src/app/modules/auth/components/auth-layout/auth-layout.component';
import { RecuperarClaveComponent } from './pages/recuperarclave/recuperar-clave.component';
import { CambiarClaveComponent } from './pages/cambiar-clave/cambiar-clave.component';
const routes: Routes = [
  { path: 'registro-usuario', component: RegistroUsuarioComponent },
  { path: 'recuperar-clave', component: RecuperarClaveComponent },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginsisComponent },
      { path: 'cambia', component: CambiarcComponent },
      { path: 'restablecer-password', component: CambiarcComponent },
      { path: '', component: LoginsisComponent },
      { path: 'cambiar-clave/:ruta', component: CambiarClaveComponent },
      /*   {path: '**',redirectTo:'/login',pathMatch: 'full'}, */
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
