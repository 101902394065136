import { AdsibUtilService } from './../../services/adsib-util.service'
import { lastValueFrom } from 'rxjs'
import { TramiteService } from 'src/app/core/services/tramite.service'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatDialogRef } from '@angular/material/dialog'
import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service'
import { Parametricas } from '../../enums/parametricas.enums'
import { ObservacionService } from '../../services/observacion.service'

@Component({
  selector: 'app-observa-tramite',
  templateUrl: './observa-tramite.component.html',
  styleUrls: ['./observa-tramite.component.scss']
})
export class ObservaTramiteComponent implements OnInit {

  idTramite: number
  roles: any[] = []
  rolesObservados: any[] = []
  rolesDerivados: any[] = []
  estadosObservados: any[] = []
  estadosRetorno: any[] = []
  tramiteObservadoForm: FormGroup
  usuario: any
  tramite: any
  rolesObservadosFinal: any[] = []
  rolesDerivadosFinal: any[] = []


  constructor(
    private dialogRef: MatDialogRef<ObservaTramiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private tramiteService: TramiteService,
    private usuarioService: UsuarioService,
    private observacionService: ObservacionService,
    private adsibUtilService: AdsibUtilService
  ) {
    this.idTramite = this.data.idTramite
  }

  ngOnInit(): void {
    // this.dialogRef.updateSize('980px', '700px')
    this.dialogRef.updateSize('700px', 'auto')
    this.usuario = this.usuarioService.usuario

    this.tramiteObservadoForm = this.formBuilder.group({
      rol_observado_id: ['', Validators.required],
      rol_observado_estado: ['', Validators.required],
      rol_retorno_id: ['', Validators.required],
      rol_retorno_estado: ['', Validators.required],
      observacion: ['', Validators.required]
    })

    this.obtenerEstadosTipoTramite()
  }

  async obtenerEstadosTipoTramite() {

    try {
      var respEstadosTramite = await lastValueFrom(this.tramiteService.obtenerEstadosTipoTramite({ idTramite: this.idTramite }))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    this.roles = respEstadosTramite.datos.estados
    this.tramite = respEstadosTramite.datos.tramite

    // Verificamos que el id_rol del usuario logueado pertenece a los roles del modulo
    const rolEncontrado = this.roles.find(element => element.id_rol === this.usuario.idRol)
    if (!rolEncontrado) {
      Swal.fire({ text: 'Usted no está autorizado a realizar observaciones a este trámite', icon: 'warning', confirmButtonText: 'Aceptar' })
      this.dialogRef.close()
      return
    }

    var contadosArr = []
    var finalArray = []

    for (let index = 0; index < this.roles.length; index++) {
      let item = this.roles[index]

      let found = contadosArr.find(element => element == item.id_rol)
      if (found)
        continue

      contadosArr.push(item.id_rol)
      let estadosRol = []
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].id_rol == item.id_rol)
          estadosRol.push(this.roles[i])
      }

      finalArray.push({ orden_tramite: item.orden_tramite, id_rol: item.id_rol, nombre_rol: item.nombre_rol, estados: estadosRol })
    }
   
    this.rolesObservados = [];
    // Filtramos los roles permitidos a observar segun el rol del usuario logueado
    if (this.tramite.id_tipo_tramite === 8) { // Para autorización previa
      switch (this.usuario.idRol) {
        case 11: // Técnico Operaciones
          this.rolesObservados = finalArray.filter(element => element.id_rol === 3); // Administrado
          break;
        case 12: // Responsable Operaciones
          this.rolesObservados = finalArray.filter(element => element.id_rol === 11); // Técnico Operaciones
          break;
        case 19: // Jefe Unidad Jurídica VDS-SC
          this.rolesObservados = finalArray.filter(element => element.id_rol === 30); // Profesional Legal VDS-SC
          break;
        case 20: // Viceministro de Defensa Social y Sustancias Controladas
          this.rolesObservados = finalArray.filter(element => element.id_rol === 19); // Jefe Unidad Jurídica VDS-SC
          break;
        case 21: // Director General
          this.rolesObservados = finalArray.filter(element => element.id_rol === 12); // Responsable Operaciones
          break;
        case 30: // Profesional Legal VDS-SC
          this.rolesObservados = finalArray.filter(element => element.id_rol === 21); // Director General
          break;
        default:
          // this.rolesObservados = [];
          this.rolesObservados = finalArray.filter(element => element.orden_tramite < rolEncontrado.orden_tramite)
          break;
      }
    }
    if (this.tramite.id_tipo_tramite === 33) { // Para Notificaciones autorización previa
      switch (this.usuario.idRol) {
        case 36: // revisor
          this.rolesObservados = finalArray.filter(element => element.id_rol === 3); // Administrado
          break;
        case 10: // Distrital
          this.rolesObservados = finalArray.filter(element => element.id_rol === 36); // revisor
          break;
        default:
          // this.rolesObservados = [];
          this.rolesObservados = finalArray.filter(element => element.orden_tramite < rolEncontrado.orden_tramite)
          break;
      }
    }

    if (this.tramite.id_tipo_tramite === 12) { // Para registros
      switch (this.usuario.idRol) {
        case 36: // revisor
          this.rolesObservados = finalArray.filter(element => element.id_rol === 3); // Administrado
          break;
        /*   case 9: // inspector
            this.rolesObservados = finalArray.filter(element => element.id_rol === 36); // Revisor
            break; */
        case 9: // inspector
          this.rolesObservados = finalArray.filter(element => element.id_rol === 3); // Administrado
          // this.rolesObservados = finalArray.filter(element => element.id_rol === 36);
          break;
        case 10: // distrital
          this.rolesObservados = finalArray.filter(element => element.id_rol === 3); // administrado         
          break;


        default:
          //this.rolesObservados = [];
          this.rolesObservados = finalArray.filter(element => element.orden_tramite < rolEncontrado.orden_tramite)
          break;
      }
    }
    
    /**observacion actualizacion de datos */
    if (this.tramite.id_tipo_tramite === 17) {
      switch (this.usuario.idRol) {
        case 36: // revisor
          this.rolesObservados = finalArray.filter(element => element.id_rol === 3); // Administrado
          break;
        /*   case 9: // inspector
            this.rolesObservados = finalArray.filter(element => element.id_rol === 36); // Revisor
            break; */
        case 9: // inspector
          this.rolesObservados = finalArray.filter(element => element.id_rol === 3); // Administrado
          // this.rolesObservados = finalArray.filter(element => element.id_rol === 36);
          break;
        case 10: // distrital
          this.rolesObservados = finalArray.filter(element => element.id_rol === 9); // Inspector         
          break;

        default:
          //this.rolesObservados = [];
          this.rolesObservados = finalArray.filter(element => element.orden_tramite < rolEncontrado.orden_tramite)
          break;
      }
    }

    // this.rolesObservados = finalArray.filter(element => element.orden_tramite < rolEncontrado.orden_tramite)
  

    // Filtramos los roles permitidos a derivar segun el rol del usuario logueado
    if (this.tramite.id_tipo_tramite === 8) { // Para autorización previa
      switch (this.usuario.idRol) {
        case 11: // Técnico Operaciones
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 11);
          break;
        case 12: // Responsable Operaciones
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 12);
          break;
        case 19: // Jefe Unidad Jurídica VDS-SC
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 19);
          break;
        case 20: // Viceministro de Defensa Social y Sustancias Controladas
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 20);
          break;
        case 21: // Director General
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 21);
          break;
        case 30: // Profesional Legal VDS-SC
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 30);
          break;
        default:
          this.rolesDerivados = finalArray.filter(element => element.orden_tramite <= rolEncontrado.orden_tramite)
          //this.rolesDerivados = [];
          break;
      }
    }
    if (this.tramite.id_tipo_tramite === 33) { // Para notificacion autorización previa
      switch (this.usuario.idRol) {
        case 36: // revisor
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 36);
          break;
        case 10: // Distrital
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 10);
          break;
        default:
          this.rolesDerivados = finalArray.filter(element => element.orden_tramite <= rolEncontrado.orden_tramite)
          //this.rolesDerivados = [];
          break;
      }
    }

    if (this.tramite.id_tipo_tramite === 12) { // Para REGISTRO
      switch (this.usuario.idRol) {
        case 36: // REVISOR
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 36);
          break;
        case 9: // inspector
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 9);
          break;

        case 10: // distrital
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 10);
          break;
        default:
          this.rolesDerivados = finalArray.filter(element => element.orden_tramite <= rolEncontrado.orden_tramite)
          //  this.rolesDerivados = [];
          break;
      }
    }

    if (this.tramite.id_tipo_tramite === 17) { // Para actualizacion datos
      switch (this.usuario.idRol) {
        case 36: // REVISOR
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 36);
          break;
        case 9: // inspector
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 36);
          break;

        case 10: // distrital
          this.rolesDerivados = finalArray.filter(element => element.id_rol === 10);
          break;
        default:
          this.rolesDerivados = finalArray.filter(element => element.orden_tramite <= rolEncontrado.orden_tramite)
          //  this.rolesDerivados = [];
          break;
      }
    }



    // this.rolesDerivados = finalArray.filter(element => element.orden_tramite <= rolEncontrado.orden_tramite)
  

    this.rolesObservadosFinal = this.rolesObservados
    this.rolesDerivadosFinal = this.rolesDerivados

  }

  verEstados(event, tipoRolSeleccionado) {

    const rolSeleccionado = event.value
    let role = null

    if (tipoRolSeleccionado == Parametricas.ROL_OBSERVADO) {

      role = this.rolesObservados.find(rol => rol.id_rol == rolSeleccionado)

      this.tramiteObservadoForm.patchValue({ rol_observado_estado: null })
      this.estadosObservados = [...role.estados]

      if (this.estadosObservados.length == 1) {
        let estadoObservado = this.estadosObservados[0]
        this.tramiteObservadoForm.patchValue({ rol_observado_estado: estadoObservado.id_estado })
      }

      // Poblamos los RolesDerivados de acuerdo al RolObservado seleccionado
      this.rolesDerivados = this.rolesDerivadosFinal

      // this.rolesDerivados = this.rolesDerivados.filter(element => element.orden_tramite > role.orden_tramite)
      this.rolesDerivados = this.rolesDerivados.filter(element => element.orden_tramite)
    }

    if (tipoRolSeleccionado == Parametricas.ROL_DERIVADO) {
      role = this.rolesDerivados.find(rol => rol.id_rol == rolSeleccionado)

      this.tramiteObservadoForm.patchValue({ rol_retorno_estado: null })
      this.estadosRetorno = [...role.estados]

      if (this.estadosRetorno.length == 1) {
        let estadoRetorno = this.estadosRetorno[0]
        this.tramiteObservadoForm.patchValue({ rol_retorno_estado: estadoRetorno.id_estado })
      }
    }
  }

  async guardar() {
  
    // Establecemos en "touched" todos los formControls del FormGroup principal
    this.tramiteObservadoForm.markAllAsTouched()

    let observacion = this.tramiteObservadoForm.value.observacion
    observacion = observacion.trim().toUpperCase()
    this.tramiteObservadoForm.controls.observacion.setValue(observacion)

    if (this.tramiteObservadoForm.invalid) {
      Swal.fire({ text: 'Debe completar el formulario', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    let data = Object.assign({ idTramite: this.idTramite }, this.tramiteObservadoForm.value)

    Swal.fire({ text: 'Guardando', icon: 'info', allowOutsideClick: false })
    Swal.showLoading()

    try {
      var resp = await lastValueFrom(this.observacionService.guardarObservacion(data))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    if (!resp.datos.pdf64) {
      Swal.fire({ text: "Sucedio un error al generar el documento", icon: "warning", confirmButtonText: 'Aceptar' })
      return
    }

    Swal.close()

    // Documento pdf generado correctamente: Enviamos el documento al Servicio Adsib para que sea firmado
    try {
      var respFirmadDB = await this.adsibUtilService.firmarDocumentoDigitalmente(resp.datos.pdf64, resp.datos.idv4)
    } catch (error) {
       Swal.fire('', error?.error?.mensaje, 'warning');   
      return
    }
   
    //////enviar correo de la observació
    if(!respFirmadDB){
    return
    }else{
    try {
      var correo = await lastValueFrom(this.observacionService.envioCorreoObservaciones(this.idTramite));
    } catch (error) {

      Swal.fire('', error.error.mensaje, 'warning');
      return;
    }
  }
    /////fin de envio de correo
    // Cerramos el componente de Observacion y le enviamos una respuesta final al componente que lo invoco
    this.dialogRef.close({ res: true, code: 1 })
  }

  cancelar() {
    this.dialogRef.close({ res: false, code: 0 })
  }
}
