<button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="cancelar()">
    <mat-icon class="close-icon" matTooltip="Cerrar dialogo">close</mat-icon>
</button>
<button mat-icon-button class="close-button" (click)="minimizar()" *ngIf="isFullScreen">
    <mat-icon class="close-icon" matTooltip="Restaurar pantalla">fullscreen_exit</mat-icon>
</button>
<button mat-icon-button class="close-button" (click)="maximizar()" *ngIf="!isFullScreen">
    <mat-icon class="close-icon" matTooltip="Pantalla completa">fullscreen</mat-icon>
</button>

<div class="row mt-2">
    <div class="col text-center">
        <h2>{{titulo}}</h2>
    </div>
    <div>
        <p style="font-size: 13px;">{{subtitulo}}</p>
    </div>
</div>
<div style="display:flex;justify-content: space-between;">
    <div class="ms-5">
        <span style="font-size: 13px; font-weight: 500;">* {{documentTitle}} *</span>
    </div>
    <div class="me-5">
        <span style="font-size: .75rem;"><strong>{{pdfBase64Array.length == 0 ? 0 : counter + 1}}</strong> de <strong>{{pdfBase64Array.length}}</strong> documentos </span>
    </div>
</div>

<div class="row" style="position: relative;">
    <div class="col" [ngStyle]="{'height.px': pdfViewerHeight}" style="padding-left: 0px;padding-right: 0px;">
        <app-pdf-viewer [source]="source" [base64]="base64" [url]="url"></app-pdf-viewer>
    </div>

    <button mat-fab (click)="previousNextPdf(-1)" color="secondary" style="position:absolute;left: 0;"
        [ngStyle]="{'top.px': fabButtonPosition}">
        <mat-icon>navigate_before</mat-icon>
    </button>

    <button mat-fab (click)="previousNextPdf(1)" color="secondary" style="position:absolute;right: 0;"
        [ngStyle]="{'top.px': fabButtonPosition}">
        <mat-icon>navigate_next</mat-icon>
    </button>
</div>

<div class="row mt-4">
    <div class="col text-end">
        <button mat-stroked-button color="simple-outline" class="mx-3" (click)="cancelar()">Cancelar</button>
        <button mat-button [ngClass]="botonExtra.tipo=='stroked' ? 'mat-stroked-button' : 'mat-flat-button'"
            class="mx-3" *ngFor="let botonExtra of botonesExtra"
            [color]="botonExtra.tipo=='stroked' ? botonExtra.color + '-outline' : botonExtra.color"

            (click)="accionesExtra(botonExtra.code)">{{ botonExtra.texto }}</button>
        <button mat-raised-button color="primary" class="mx-3" (click)="aceptar()" *ngIf="textoBoton!='nada'">{{textoBoton}}</button>
    </div>
</div>