import { Component, OnInit, Inject,ViewChild } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import Swal from "sweetalert2"
import { lastValueFrom, Observable } from "rxjs";
import { UsuarioService } from "src/app/modules/auth/services/usuario.service"
import { Parametricas } from "../../../core/enums/parametricas.enums"
import { TramiteService } from "src/app/core/services/tramite.service"
import { FormGroup, FormBuilder } from "@angular/forms"
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: "app-reasigna-tramite",
  templateUrl: "./reasigna-tramite.component.html",
  styleUrls: ["./reasigna-tramite.component.scss"],
})
export class ReasignaTramiteComponent implements OnInit {
     /**
   * formulario de reasignacion
   */
  public reasignacionForm: FormGroup
     /**
   *parametros de tituolos
   */
  tituloC = Parametricas.TITULOREASIGNACION
     /**
   * parametros de subtitulos
   */
  subtituloC = Parametricas.subtituloReasignacion
     /**
   * variable tramite
   */
  idTramite: number;
    /**
   * variable tramite
   */
  tramite: any;

    /**
   * @ignore
   */
    dataServidores = null
      /**
   * @ignore
   */
  datos: any[]

    /**
   * @ignore
   */
    @ViewChild(MatSort) sort: MatSort;
      /**
   * @ignore
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tramiteService: TramiteService,
    private formBuilder: FormBuilder,
    /**
     * Constructor  que permite consumir los servicios de usuarios
     */
    public _usuarioService: UsuarioService,
     /**
     * objeto dialog para el listado de solicitudes ap
     */
     public dialog: MatDialog,
    public dialogreasigna: MatDialogRef<ReasignaTramiteComponent>
  ) {
    (this.idTramite = data.idTramite),
      (this.reasignacionForm = this.formBuilder.group({
        reasignacion_glosa: [""],
      }))  
  }

  ngOnInit(): void {
    this.obtenerDatosTramite();
  }

      /**
   * funcion de obtener datos del tramite 
   */
  async obtenerDatosTramite() { 
    try {
      var respDatosTramite = await lastValueFrom(
        this.tramiteService.obtenerDatosTramite({ idTramite: this.idTramite })
      );
    } catch (error) {
      Swal.fire({
        text: error.error.mensaje,
        icon: "warning",
        confirmButtonText: "Aceptar",
      })
      return
    }
    this.tramite = respDatosTramite.datos.datosTramite
  }
  
      /**
   *Funcion de reasignacion detramite
   */
  async GuardarReasignar() {   
   
      var resp = await Swal.fire({
        title:"¿Desea guardar el registro?", text:"¡No podrás revertir esto! ",icon: "info",
        showCancelButton: true,confirmButtonText: "Si, guardar",
        cancelButtonText: "No, cancelar",
        showCloseButton: true })   
        if (!resp.isConfirmed) return
        Swal.fire("", "Espere por favor", "info");
        Swal.fire({
          title: "Espere por favor...",
          html: "¡Almacenando Registro!",
          allowOutsideClick: false,
        });
        Swal.showLoading()
      const params = {
        idTramite: this.data.idTramite,
        idEstado: this.data.idEstado,
        idTipoTramite: this.data.idTipoTramite,
        reasignacionForm: this.reasignacionForm.value.reasignacion_glosa,
      };
     
      try {
         var respuesta = await lastValueFrom(this._usuarioService.reasignarTramite(params))
      } catch (error) {
        Swal.fire("Error2", error.error.mensaje, "error");
        return;
      }
      Swal.close()
      Swal.fire({title:"¡Guardado!", text:"Registro exitoso.",icon: "success",
      confirmButtonText: 'Aceptar',showCloseButton: true})
      
      this.dialogreasigna.close({ res: true, code: 1 });
      return;
  
  }
  
      /**
   * funcion cerrar component
   */
  cancelar() {
    this.dialogreasigna.close({ res: false, code: 0 }); // false: cancelar
  }

}
