import { lastValueFrom } from 'rxjs';
import { TramiteService } from 'src/app/core/services/tramite.service';
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import Swal from 'sweetalert2';

@Component({
  selector: "app-visor-pdf-multiple",
  templateUrl: "./visor-pdf-multiple.component.html",
  styleUrls: ["./visor-pdf-multiple.component.scss"],
})
export class VisorPdfMultipleComponent implements OnInit {

  defaultDialogHeight: number;
  maxDialogHeight: number;
  screenWidth: number;
  screenHeight: number;

  dialogWidth: number;
  dialogHeight: number;
  pdfViewerHeight: number;
  fabButtonPosition: number;

  headerDialogHeight: number;
  footerDialogHeight: number;
  fabHeightButton: number = 56; // Height in pixels

  isFullScreen: boolean;

  source = "";
  base64: boolean = true;
  url: boolean = false;

  titulo = "";
  subtitulo = "";
  textoBoton = "";
  botonesExtra = [];
  idTramite: number;

  pdfBase64Array = [];
  documentTitle: string = '';
  counter: number = 0;

  constructor(
    public dialogVisorPdf: MatDialogRef<VisorPdfMultipleComponent>,
    private tramiteService: TramiteService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.defaultDialogHeight = 0.75;
    this.maxDialogHeight = 0.97;
    this.headerDialogHeight = 115; // Size in pixels
    this.footerDialogHeight = 90; // Size in pixels
    this.isFullScreen = false;

    this.resizeDialog(this.defaultDialogHeight);

    this.titulo = this.data && this.data.titulo ? this.data.titulo : 'Visualización de Archivos';
    this.subtitulo = this.data && this.data.subtitulo ? this.data.subtitulo : '';
    this.textoBoton = this.data && this.data.textoBoton ? this.data.textoBoton : 'Siguiente';
    this.botonesExtra = this.data && this.data.botonesExtra ? this.data.botonesExtra : [];
    this.idTramite = this.data && this.data.idTramite ? this.data.idTramite : 0;

    if (!this.idTramite) {
      Swal.fire({ text: 'Debe especificar un trámite', icon: 'warning', confirmButtonText: 'Aceptar' });
      this.dialogVisorPdf.close({ res: false }); // false: cancelar
      return;
    }

    this.cargarDocumentos();
  }

  maximizar() {
    this.resizeDialog(this.maxDialogHeight);
    this.isFullScreen = true;
  }

  minimizar() {
    this.resizeDialog(this.defaultDialogHeight);
    this.isFullScreen = false;
  }

  resizeDialog(newHeight: number, newWidth: number = null) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    newWidth = newWidth || 1; // Si no recibe ningun ancho se le asigna el ancho de la pantalla

    this.dialogWidth = this.screenWidth * newWidth;
    this.dialogHeight = this.screenHeight * newHeight;

    if (this.dialogWidth > this.dialogHeight * 1.5)
      this.dialogWidth = this.dialogHeight * 1.5;

    this.pdfViewerHeight = this.dialogHeight - this.headerDialogHeight - this.footerDialogHeight;
    this.fabButtonPosition = (this.pdfViewerHeight / 2) - (this.fabHeightButton / 2);

    this.dialogVisorPdf.updateSize(`${this.dialogWidth}px`, `${this.dialogHeight}px`)
  }

  async cargarDocumentos() {
/*     Swal.fire({ title: '', text: 'Bsucando informacion de los archivos', icon: 'info', allowOutsideClick: false });
    Swal.showLoading();
    try {
      var docs = await lastValueFrom(this.tramiteService.obtenerDocumentos(this.idTramite))
      console.log(docs,'documentos');
      
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: "error", confirmButtonText: 'Aceptar' })
      return
    }
    Swal.close() */

    
    
    Swal.fire({ title: '', text: 'Cargando los documentos, espere por favor!', icon: 'info', allowOutsideClick: false });
    Swal.showLoading();

    try {
      var docs = await lastValueFrom(this.tramiteService.obtenerDocumentos(this.idTramite))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: "error", confirmButtonText: 'Aceptar' })
      return
    }
    Swal.close()

    this.pdfBase64Array = docs.datos.documentosBase64;
   if (this.pdfBase64Array.length == 0) {
      Swal.fire({ html: '¡No existe documentos del trámite!', icon: 'warning', confirmButtonText: 'Aceptar' })
      this.cancelar()
    }
    
  //  Seleccionamos el primer archivo base64 para mostrarlo
    if (this.pdfBase64Array.length > 0) {
      this.documentTitle = this.pdfBase64Array[this.counter].titulo;
      this.source = this.pdfBase64Array[this.counter].base64
    }

    const mensajesError = docs.datos.mensajesError;
     if (mensajesError.length > 0) {
      let mensaje = '<strong><p>No se encontró los siguientes archivos:</p></strong>'
      for (let mensajeError of mensajesError)
        mensaje += `<div style="text-align: justify;"><span><img src="assets/svg/right-arrow-next-2-svgrepo-com.svg" alt="" height="20" width="20">
        ${mensajeError.mensaje}</span><div><br>`
      Swal.fire({ html: mensaje, icon: 'warning', confirmButtonText: "Aceptar" })
    }    
  }

  aceptar() {
    this.dialogVisorPdf.close({ res: true, code: 1 }); // true: aceptar
  }

  accionesExtra(code: number) {
    this.dialogVisorPdf.close({ res: true, code: code }); // true: rechazar, code: LO DEFINE EL QUE SOLICITA 
  }

  cancelar() {
    this.dialogVisorPdf.close({ res: false }); // false: cancelar
  }

  previousNextPdf(value: number) {
    if (this.pdfBase64Array.length == 0) return;

    this.counter += value;

    if (this.counter <= 0) {
      this.counter = 0;
    }

    if (this.counter >= this.pdfBase64Array.length) {
      this.counter = this.pdfBase64Array.length - 1;
    }

    this.documentTitle = this.pdfBase64Array[this.counter].titulo;
    this.source = this.pdfBase64Array[this.counter].base64;
  }
}
