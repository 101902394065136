import { Component, OnInit } from '@angular/core';
import { Parametricas } from 'src/app/core/enums/parametricas.enums';
import { environment } from 'src/environments/environment';
/**
 * Componente que tiene un layout para las ventanas de autentificacion
 */
@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  public authTitle=Parametricas.authTitle
  public authSubtitle=Parametricas.authSubtitle
  public dgsc=environment.base_url_backend=='https://edapi.mingobierno.gob.bo/dgsc_api'? false:true;
  public environment=environment.production
  /**
   * Constructor del objeto
   */ 
  constructor() { }
  
  /**
   * @ignore
   */  
  ngOnInit(): void {
  }

}
