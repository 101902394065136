import { lastValueFrom } from 'rxjs';
import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { UsuarioService } from "src/app/modules/auth/services/usuario.service"
import Swal from "sweetalert2"
import { CodigoVerificacionService } from "../../services/codigo-verificacion.service"
import { Parametricas } from "src/app/core/enums/parametricas.enums"

@Component({
  selector: "app-recuperar-clave",
  templateUrl: "./recuperar-clave.component.html",
  styleUrls: ["./recuperar-clave.component.scss"],
})
export class RecuperarClaveComponent implements OnInit {
  public authTitle=Parametricas.authTitle
  public authSubtitle=Parametricas.authSubtitle
  @ViewChild("stepper") stepper
  recuperaForm: FormGroup
  thirdFormGroup: FormGroup
  idUsuario: number

  secondStepComplete: boolean = false
  thirdStepComplete: boolean = false
  isFormCompleted: boolean = false

  passwordNoSonIguales: boolean = false
  public hide = true
  public hideTwo = true

  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    public codigoVerificacionService: CodigoVerificacionService
  ) { }

  ngOnInit(): void {
    this.recuperaForm = this.formBuilder.group({
      correo: ["", Validators.required],
      password: ["", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        ),
      ]],
      passwordConfirm: ["", [Validators.required, Validators.minLength(8)]],
    })
    this.thirdFormGroup = this.formBuilder.group({
      codigo: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    })
  }
  formsAreValid() {
    return this.recuperaForm.valid;
  }
  async solicitarRestablecerClave() {
    if (this.recuperaForm.invalid)
      return

    this.passwordNoSonIguales = false

    if (this.recuperaForm.value.password !== this.recuperaForm.value.passwordConfirm) {
      this.passwordNoSonIguales = true
      return
    }

    Swal.fire({ title: "", text: "Restableciendo su contraseña", icon: "info", allowOutsideClick: false })
    Swal.showLoading()

    try {
      var resp = await this.usuarioService.solicitarRestablecerClave(this.recuperaForm.value.correo).toPromise()
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: "warning", confirmButtonText: 'Aceptar' })
      return
    }

    Swal.close()
    this.idUsuario = resp.datos.idUsuario
    this.secondStepComplete = true
    Swal.fire({
      title: "Código de verificación enviado",
      text: 'Se ha enviado un código de verificación a su correo electrónico',
      icon: "success",
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      this.stepper.next()
    })

  }

  async restablecerClave() {
    if (this.thirdFormGroup.invalid)
      return

    // Enviar al Backend para guardar los datos de Usuario
    const datosVerificacion = this.thirdFormGroup.value

    Swal.fire({ title: "", text: "Verificando codigo", icon: "info", allowOutsideClick: false })
    Swal.showLoading()

    try {
      var resp = await lastValueFrom(this.usuarioService.restablecerClave({
        claveNueva: this.recuperaForm.value.password,
        idUsuario: this.idUsuario,
        codigo: datosVerificacion.codigo,
      }))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: "warning", confirmButtonText: 'Aceptar' })
      return
    }

    this.thirdStepComplete = true
    Swal.fire({ text: resp.mensaje, icon: "success", confirmButtonText: 'Aceptar' })
      .then((result) => {
        this.isFormCompleted = true
        this.stepper.next()
      })
  }

  async solicitarCodigo() {
    console.log('solicitar codigi::;', this.recuperaForm.value)
    const data = {
      tipoCodigo: Parametricas.RESET_PASSWORD,
      idUsuario: this.idUsuario,
      email: this.recuperaForm.value.correo
    }

    try {
      await this.codigoVerificacionService.solicitarCodigo(data)
    } catch (error) {
      return
    }
  }
}
