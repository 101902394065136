import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import Swal from 'sweetalert2'
import { Token } from '../models/token.model'
import { PinFDComponent } from '../components/pin-fd/pin-fd.component'
import { FirmaDigitalService } from './firma-digital.service'
import { VisorPdfDialogoComponent } from '../components/visor-pdf-dialogo/visor-pdf-dialogo.component'
import { lastValueFrom } from 'rxjs'
import { TramiteService } from './tramite.service'
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { UsuarioService } from "../../../app/modules/auth/services/usuario.service";
import { Router } from '@angular/router'
import { SocketService } from 'src/app/core/services/socket.service';
import { RegistroTramiteService } from 'src/app/modules/registros/services/registroTramite.service'
@Injectable({
  providedIn: 'root'
})
export class AdsibUtilService {
  /**
 * variable de notificacion
 */
  Snacknotificacion: string;
  tipoTramite: any
  constructor(private _snackBar: MatSnackBar,
    private socketService: SocketService,
    public firmaDigitalService: FirmaDigitalService,
    public dialog: MatDialog,
    public pdfModalDialog: MatDialog,
    private tramiteService: TramiteService,
    private registroTramiteService: RegistroTramiteService,
    private usuarioService: UsuarioService,
    private router: Router,
  ) { }

  async firmarDocumentoDigitalmente(pdf64, uuidv4) {

    // Visualizamos el pdf generado en un modal
    const dialogModalPdf = this.pdfModalDialog.open(VisorPdfDialogoComponent, {
      disableClose: true,
      data: { archivoBase64: pdf64 }
    })

    // Obtenemos la respuesta del boton que presiono en el modal
    var respOptionSelected = await lastValueFrom(dialogModalPdf.afterClosed())

    if (!respOptionSelected || !respOptionSelected.res) {
      // Se cerro el modal de alguna manera sin presionar el boton de firmar
      //  throw { mensaje: 'Cancelado' }
      return
    }

    if (respOptionSelected.res === false || respOptionSelected.code === 0) {
      // Se presiono el boton de cancelar
      //throw { mensaje: '' }
      return
    }

    // Si presiono el boton de firmar procedemos a firmar el documento
    if (respOptionSelected.res === true && respOptionSelected.code === 1) {
      // console.log('firmando documento')

      // Firmamos el documento generado con Adsib
      // try {
      //   var archivosFirmados = await this.firmarDocumento(pdf64)
      // } catch (error) {
      //   Swal.fire({ text: error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      //   throw { mensaje: 'Sucedio un error al intentar firmar el documento generado' }
      // }

      // if (!archivosFirmados.finalizado) {
      //   Swal.fire({ text: archivosFirmados.mensaje, icon: "error", confirmButtonText: 'Aceptar' })
      //   throw { mensaje: 'Sucedio un error al intentar firmar el documento generado' }
      // }

      // // Procedemos a guardar este archivo firmado (base64) en el servidor
      // const documentoFD = {
      //   pdfBase64string: archivosFirmados.datos.pdf_firmado,
      //   uuidv4: uuidv4
      // }

      const documentoFD = {
        pdfBase64string: pdf64,
        uuidv4: uuidv4
      }

      // Swal.fire({ text: "Guardando el documento firmado digitalmente en los servidores", icon: "info", allowOutsideClick: false })
      Swal.fire({ text: "Guardando el documento en los servidores", icon: "info", allowOutsideClick: false })
      Swal.showLoading()

      try {

        var respuesta = await lastValueFrom(this.tramiteService.guardarDocumentoFD(documentoFD))

        // this.socketService.sendMessage("cualquie cosa desde admin")
      } catch (error) {
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        // throw { mensaje: 'Aun no es posible aprobar el tramite' }
        throw { mensaje: 'Error al intentar guardar documento' }
      }

      /***dias laborales, feriados */
      /*    if (respuesta.datos.horario.identificador == 1) {
           if (respuesta.datos.diaLaboral == 0 || respuesta.datos.diaLaboral == 6 || respuesta.datos.diaFeriadoNacional != 0 ||
             respuesta.datos.diaFeriadoDepartamental != null) {
               Swal.fire({
                 title:"¡Día no laboral y/o feriado!", text:"",icon: "info",
                 confirmButtonText: 'Aceptar',showCloseButton: true })
           } 
         } *//*  else {
        Swal.fire({ text: respuesta.datos.men, icon: 'warning', confirmButtonText: 'Aceptar' })
      } */

      /***snackbar ****/

      /*      if(this.usuarioService.usuario.idRol=='9' || this.usuarioService.usuario.idRol=='10' || this.usuarioService.usuario.idRol=='11' ||
           this.usuarioService.usuario.idRol=='12' || this.usuarioService.usuario.idRol=='15' ||
           this.usuarioService.usuario.idRol=='16' || this.usuarioService.usuario.idRol=='21' ||
           this.usuarioService.usuario.idRol=='30' || this.usuarioService.usuario.idRol=='19' ||
           this.usuarioService.usuario.idRol=='7' ){
                 this.Snacknotificacion=respuesta.datos.men
                 this.tipoTramite=respuesta.datos.id_tipo_tramite
                 if(this.Snacknotificacion!=''||this.Snacknotificacion!=null ||this.Snacknotificacion!=undefined){
                   setTimeout(() => {   
                   this._snackBar.open(this.Snacknotificacion, 'De acuerdo.', {
                     duration: 10000, 
                     verticalPosition: "top", // Allowed values are  'top' | 'bottom'
                     horizontalPosition: "center", // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
                     panelClass: ['snack-bar-color'],
                     data: { fontSize: '20px' }
                   })   
                 }, 5000) *////600000 => 10 minutos  / 900000 => 15 minutos
      ////para recargar pagina segun tipo de tramite
      /*   if (this.tipoTramite === 8) {
          Swal.fire({
            title: '',
            text: 'Espere por favor',
            icon: 'info',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
              this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista').then(() => {
                Swal.close();
              });
            }
          });
        } */
      //  }

      /* if(respuesta.datos.id_tipo_tramite==9){
           Swal.fire("", "Espere por favor", "info");
           Swal.fire({
             title: "Espere por favor...",       
             allowOutsideClick: false,
           });
           Swal.showLoading()
           this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista');
           Swal.close()
         } */
      //  }

      /***snackbar ****/

      Swal.fire({
        // title: "Firmado Digitalmente",
        // text: "Su documento fue firmado y almacenado exitosamente",
        title: "Registro Exitoso",
        text: "Documento almacenado exitosamente",
        icon: "success",
        confirmButtonText: 'Aceptar'
      })

      return { mensaje: 'Documento guardado exitosamente' }
      // return { mensaje: 'Documento guardado y firmado exitosamente' }

    }
  }
  async firmarDocumentoDigitalmenteCompras(pdf64, uuidv4) {
    // Visualizamos el pdf generado en un modal
    const dialogModalPdf = this.pdfModalDialog.open(VisorPdfDialogoComponent, {
      disableClose: true,
      data: { archivoBase64: pdf64 }
    })

    // Obtenemos la respuesta del boton que presiono en el modal
    var respOptionSelected = await lastValueFrom(dialogModalPdf.afterClosed())

    if (!respOptionSelected || !respOptionSelected.res) {
      // Se cerro el modal de alguna manera sin presionar el boton de firmar
      //  throw { mensaje: 'Cancelado' }
      return
    }

    if (respOptionSelected.res === false || respOptionSelected.code === 0) {
      return
    }

    // Si presiono el boton de firmar procedemos a firmar el documento
    if (respOptionSelected.res === true && respOptionSelected.code === 1) {
      const documentoFD = {
        pdfBase64string: pdf64,
        uuidv4: uuidv4
      }

      // Swal.fire({ text: "Guardando el documento firmado digitalmente en los servidores", icon: "info", allowOutsideClick: false })
      Swal.fire({ text: "Guardando el documento en los servidores", icon: "info", allowOutsideClick: false })
      Swal.showLoading()

      try {

        var respuesta = await lastValueFrom(this.tramiteService.guardarDocumentoFDcompra(documentoFD))

        // this.socketService.sendMessage("cualquie cosa desde admin")
      } catch (error) {
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        // throw { mensaje: 'Aun no es posible aprobar el tramite' }
        throw { mensaje: 'Error al intentar guardar documento' }
      }

      /***snackbar ****/

      Swal.fire({
        // title: "Firmado Digitalmente",
        // text: "Su documento fue firmado y almacenado exitosamente",
        title: "Registro Exitoso",
        text: "Documento almacenado exitosamente",
        icon: "success",
        confirmButtonText: 'Aceptar'
      })

      return { mensaje: 'Documento guardado exitosamente' }
      // return { mensaje: 'Documento guardado y firmado exitosamente' }

    }
  }
  async firmarDocumento(pdf64) {
    var archivosFirmados = {
      finalizado: true,
      mensaje: '',
      datos: {
        pdf_firmado: pdf64,
        mensaje: '',
      }
    }

    return archivosFirmados
  }
  async firmarDocumentoBack(pdf64) {
    try {
      var result = await this.obtenerTokens()
    } catch (error) {
      throw { mensaje: error }
    }

    if (!result) throw { mensaje: 'Se cancelo la Firma Digital' }
    if (result.ok == false) throw { mensaje: 'Se canceló la Firma Digital' }

    Swal.fire({ text: "Firmando el documento", icon: "info", allowOutsideClick: false, confirmButtonText: 'Aceptar' })
    Swal.showLoading()

    try {
      var archivosFirmados = await lastValueFrom(this.firmaDigitalService.firmarPDF(result.slot, result.pin, result.alias, pdf64))
    } catch (error) {
      throw { mensaje: error }
    }

    Swal.close()

    return archivosFirmados
  }

  async obtenerTokens() {
    Swal.fire({
      text: "Validando Servicio Jacobitus Total para firmar documentos",
      icon: "info",
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar'
    })
    Swal.showLoading()

    try {
      var resp = await this.firmaDigitalService.getEstado()
      Swal.close()
    } catch (error) {
      Swal.close()
      throw "Servicio Jacobitus Total para firmar documentos no esta iniciado"
    }

    try {
      var resTokens = await this.firmaDigitalService.getTokens()
    } catch (error) {
      throw error.error.mensaje
    }

    if (!resTokens.datos || !resTokens.datos.connected) {
      throw resTokens.mensaje
    }

    // Tenemos datos del token
    let numeroTokens = resTokens.datos.tokens.length
    if (numeroTokens == 0) {
      throw "No se tiene certificados en el token conectado"
    }

    try {
      let tokens: Token[]
      tokens = resTokens.datos.tokens
      const dialogRef = this.dialog.open(PinFDComponent, {
        width: "450px",
        autoFocus: true,
        data: { tipo: "Aprobar Documento con Firma Digital", tokens: tokens },
      })
      var result = await lastValueFrom(dialogRef.afterClosed())

    } catch (error) {
      throw error.error.mensaje
    }

    return result
  }

  async validarTokens() {
    Swal.fire({
      text: "Validando Servicio Jacobitus Total para firmar documentos",
      icon: "info",
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar'
    })
    Swal.showLoading()

    try {
      const resp = await this.firmaDigitalService.getEstado()
      Swal.close()
    } catch (error) {
      Swal.close()
      throw "Servicio Jacobitus Total para firmar documentos no esta iniciado"
    }

    try {
      var resTokens = await this.firmaDigitalService.getTokens()
    } catch (error) {
      throw error.error.mensaje
    }

    if (!resTokens.datos) {
      throw resTokens.mensaje
    }
    if (!resTokens.datos.connected) {
      throw resTokens.mensaje
    }

    return { ok: true }
  }

  async aprobarDocumentoRevisor(pdf64, uuidv4, datos) {
    // Visualizamos el pdf generado en un modal
    const dialogModalPdf = this.pdfModalDialog.open(VisorPdfDialogoComponent, {
      disableClose: true,
      data: { archivoBase64: pdf64 }
    })

    // Obtenemos la respuesta del boton que presiono en el modal
    var respOptionSelected = await lastValueFrom(dialogModalPdf.afterClosed())
    // Si presiono el boton de firmar procedemos a firmar el documento
    if (respOptionSelected.res === true && respOptionSelected.code === 1) {
      const documentoFD = {
        pdfBase64string: pdf64,
        uuidv4: uuidv4,
        datos : datos
      }
      // Swal.fire({ text: "Guardando el documento firmado digitalmente en los servidores", icon: "info", allowOutsideClick: false })
      Swal.fire({ text: "Guardando el documento en los servidores", icon: "info", allowOutsideClick: false })
      Swal.showLoading()

      try {
        var respuesta = await lastValueFrom(this.registroTramiteService.aprobarRequisitosRevisor(documentoFD))
        //var respuesta = await lastValueFrom(this.tramiteService.guardarDocumentoFD(documentoFD))
        // this.socketService.sendMessage("cualquie cosa desde admin")
      } catch (error) {
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        throw { mensaje: 'Error al intentar guardar documento' }
        // throw { mensaje: 'Sucedio un error al intentar guardar el documento firmado' }
      }
      Swal.close()
      
      Swal.fire({
        title: "Registro Exitoso",
        text: "Documento almacenado exitosamente",
        icon: "success",
        confirmButtonText: 'Aceptar'
      })
      return { mensaje: 'Documento guardado exitosamente' }
    }
  }
}
