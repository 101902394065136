import { Component, OnInit, inject } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";
import { Usuario } from "../../../modules/auth/models/usuario.model";
import { UsuarioService } from "../../../modules/auth/services/usuario.service";
import { Router } from "@angular/router";
import { NotificacionesComponent } from "../notificaciones/notificaciones.component"
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { lastValueFrom, Observable } from "rxjs";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavComponent implements OnInit {
  durationInSeconds = 5;
  badgeCount: number;
  public usuario: Usuario;
  public estadoFD: boolean = false;
  public showSubmenu: boolean = false;
  public isExpanded: boolean = false;
  public isShowing: boolean = false;

  notificacion: any;
  public menus: any[] = [];
  public isProduction = environment.production
  public dgsc=environment.base_url_backend=='https://edapi.mingobierno.gob.bo/dgsc_api'? false:true;  

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(private _snackBar: MatSnackBar,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public _usuarioService: UsuarioService
    
  ) {
   
    this.usuario = _usuarioService.usuario;
    console.log('DATOS DE USUARIO', this.usuario)

    if (!this.usuario) {
      let ruta = "/login";
      this.router.navigate([ruta]);
    }

    this.menus = [
      /*
      {
        nombre: "Administración",
        img: "admin_panel_settings",
        ruta: "",
        mostrar: true,
        submenu: [
          {
            nombre: "Funcionarios",
            img: "supervisor_account",
            ruta: "/admin/usuarios",
          },
        ],
      },
      */
/*
      {
        nombre: "Reportes",
        img: "description",
        ruta: "",
        mostrar: true,
        submenu: [
          {
            nombre: "Tramites no presentados",
            img: "insert_comment",
            ruta: "/descargo/no-presentados",
          },
          {
            nombre: "Tramites fuera de plazo",
            img: "insert_comment",
            ruta: "/descargo/fuera-de-plazo",
          },
        ],
      },
      */
    ];
/*
    this.menus = [
      {
        nombre: "Conciliaciones", img: "file_copy", ruta: "", mostrar: true,
        submenu: [
          { nombre: 'Conciliaciones Mensuales', ruta: "/conciliaciones/mensuales" },
          { nombre: 'Extracto bancario diario', ruta: "/conciliaciones/extracto" },
        ]
      },
      {
        nombre: "Comprobantes observados", img: "file_copy", ruta: "", mostrar: true,
        submenu: [
          { nombre: 'Observaciones por solucionar', ruta: "/conciliaciones/observaciones" },
          { nombre: 'Bloquear extractos', ruta: "/conciliaciones/bloquear" },
        ]
      },
      {
        nombre: "Correspondecia", img: "drive_file_move", ruta: "", mostrar: true,
        submenu: [
          { nombre: 'Pendientes', ruta: "/correspondencia/pendientes" },
          { nombre: 'Finalizados', ruta: "/correspondencia/finalizados" },
          { nombre: 'Reporte general', ruta: "/correspondencia/reporte" },
        ]
      },
      {
        nombre: "Reportes", img: "description", ruta: "", mostrar: true,
        submenu: [
          { nombre: 'Detalle de observaciones subsanadas', ruta: "/reportes/observaciones-subsanadas" },
          { nombre: 'Reporte de comprobantes o sanciones', ruta: "/reportes/comprobantes-sanciones" },
        ]
      },

    ];
    */

    this.menus = [...this.menus, ...this._usuarioService.menus]
  }

  ngOnInit(): void {
    console.log('DATOS DE USUARIO', this.usuario)
  }

  openSnackBar() {
    this._snackBar.openFromComponent(NotificacionesComponent, {
      duration: this.durationInSeconds * 1000,
    });
  }

  cambiarRol() {
    let ruta = "/admin/seleccionar-rol";
    this.router.navigate([ruta]);
  }
  cambiarClaveUsuario() {
    let ruta = '/cambiar-clave/' + btoa(this.router.url);
    this.router.navigate([ruta]);
  }

}

