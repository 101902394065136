import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-cambiarc',
  templateUrl: './cambiarc.component.html',
  styleUrls: ['./cambiarc.component.scss']
})
export class CambiarcComponent implements OnInit {
  resetPasswordForm = this.formBuilder.group({
    oldPassword: ['', Validators.required],
    password: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
    ]],
    passwordConfirm: ['', [
      Validators.required,
      Validators.minLength(8),
    ]],
  });
  passwordNoSonIguales: boolean = false;
  hide = true;
  hideTwo = true;
  hideOld = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void { }

  async cambiarPassword() {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    // TODO: Validate data 
    if (this.resetPasswordForm.value.password !== this.resetPasswordForm.value.passwordConfirm) {
      this.passwordNoSonIguales = true;
      return;
    }

    Swal.fire({ title: '', text: 'Cambiando contraseña', icon: 'info', allowOutsideClick: false });
    Swal.showLoading();
    try {
      await this.usuarioService.resetPassword(this.resetPasswordForm.value).toPromise();
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'warning');
      return;
    }

    Swal.fire('', 'Su contraseña se ha cambiado correctamente', 'success');
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}