import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[appUpperCaseInput]'
})
export class UpperCaseInputDirective <T extends MatInput>{

  constructor(private elementRef: ElementRef<T>, private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const value = this.elementRef.nativeElement.value.toUpperCase();
    this.ngControl.control?.setValue(value);
  }

}
