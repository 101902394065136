import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class ObservacionService {

  constructor(private baseHttpService: BaseHttpService) { }

  guardarObservacion(datos: any): Observable<any> {
    return this.baseHttpService.post('tramites/observacion/guardar', datos);
  }

  generarPdf(datos: any): Observable<any> {
    return this.baseHttpService.post('tramites/observacion/generar-pdf', datos)
  }
  /**
   * envio de correo una vez observado
   */ 
public envioCorreoObservaciones(id_tramite: string|number): Observable<any> {
  return this.baseHttpService.get(`tramites/observacion/envioCorreoObservaciones/` +id_tramite);
}

}
