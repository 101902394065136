import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { UsuarioService } from "../../services/usuario.service";
import Swal from "sweetalert2";
import * as cryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
import { lastValueFrom } from "rxjs";
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: "app-loginsis",
  templateUrl: "./loginsis.component.html",
  styleUrls: ["./loginsis.component.scss"],
})
export class LoginsisComponent implements OnInit {
  mostrarMensaje: boolean = false;
  mensaje: string = 'Bienvenido(a) al Sistema ED-6';
  loading: boolean = false;
  loadingTimeout = 10000; // 3000 milisegundos (3 segundos)

  public hide = true;
  public registro_num: string = "";
  public nombre: string = "";
  public base_url_backend = environment.base_url_backend;
  environment = environment.production;


  loginForm = this.fb.group({
    cuenta: ["", Validators.required],
    clave: ["", Validators.required],
  });

  constructor(private _snackBar: MatSnackBar,
    private router: Router,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    /**
     * Constructor que crea los servicios de solicitude AP pendientew
     */
    
  ) {}

  ngOnInit(): void {
    this.usuarioService.limpiarSesion();
  }
    // Función para eliminar espacios en blanco
    eliminarEspacios() {
      const campo1 = this.loginForm.get('cuenta');
      if (campo1.value) {
        campo1.setValue(campo1.value.trim());
      }
      const campo2 = this.loginForm.get('clave');
      if (campo2.value) {
        campo2.setValue(campo2.value.trim());
      }
    }
  async stringToBuffer(str) {
    "use strict";
    let Len = str.length,
      resPos = -1;
    // The Uint8Array's length must be at least 3x the length of the string because an invalid UTF-16
    //  takes up the equivalent space of 3 UTF-8 characters to encode it properly.
    let resArr = new Uint8Array(Len * 3);
    for (let point = 0, nextcode = 0, i = 0; i !== Len; ) {
      point = str.charCodeAt(i);
      i += 1;
      if (point >= 0xd800 && point <= 0xdbff) {
        if (i === Len) {
          resArr[(resPos += 1)] = 0xef; /*0b11101111*/
          resArr[(resPos += 1)] = 0xbf; /*0b10111111*/
          resArr[(resPos += 1)] = 0xbd; /*0b10111101*/
          break;
        }
        // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
        nextcode = str.charCodeAt(i);
        if (nextcode >= 0xdc00 && nextcode <= 0xdfff) {
          point = (point - 0xd800) * 0x400 + nextcode - 0xdc00 + 0x10000;
          i += 1;
          if (point > 0xffff) {
            resArr[(resPos += 1)] = (0x1e /*0b11110*/ << 3) | (point >>> 18);
            resArr[(resPos += 1)] =
              (0x2 /*0b10*/ << 6) | ((point >>> 12) & 0x3f); /*0b00111111*/
            resArr[(resPos += 1)] =
              (0x2 /*0b10*/ << 6) | ((point >>> 6) & 0x3f); /*0b00111111*/
            resArr[(resPos += 1)] =
              (0x2 /*0b10*/ << 6) | (point & 0x3f); /*0b00111111*/
            continue;
          }
        } else {
          resArr[(resPos += 1)] = 0xef; /*0b11101111*/
          resArr[(resPos += 1)] = 0xbf; /*0b10111111*/
          resArr[(resPos += 1)] = 0xbd; /*0b10111101*/
          continue;
        }
      }
      if (point <= 0x007f) {
        resArr[(resPos += 1)] = (0x0 /*0b0*/ << 7) | point;
      } else if (point <= 0x07ff) {
        resArr[(resPos += 1)] = (0x6 /*0b110*/ << 5) | (point >>> 6);
        resArr[(resPos += 1)] =
          (0x2 /*0b10*/ << 6) | (point & 0x3f); /*0b00111111*/
      } else {
        resArr[(resPos += 1)] = (0xe /*0b1110*/ << 4) | (point >>> 12);
        resArr[(resPos += 1)] =
          (0x2 /*0b10*/ << 6) | ((point >>> 6) & 0x3f); /*0b00111111*/
        resArr[(resPos += 1)] =
          (0x2 /*0b10*/ << 6) | (point & 0x3f); /*0b00111111*/
      }
    }
    return resArr.subarray(0, resPos + 1);
  }
  async login() {
    this.loading = true;
    if (this.loginForm.invalid) return;   
    var cuenta = this.loginForm.value.cuenta;
    var clave = this.loginForm.value.clave;
    var claveEncriptada = "";
    claveEncriptada = cryptoJS.AES.encrypt(
      clave,
      this.usuarioService.getSecretKey()
    ).toString();

    /*     claveEncriptada = crypto..publicEncrypt(
          {
            key: this.PUBLIC_KEY,
            padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
            oaepHash: "sha512",
          },
          // We convert the data string to a buffer using `Buffer.from`
          //Buffer.from(clave)
          this.stringToBuffer(clave)
        ) */
    var datos = {
      cuenta: cuenta,
      clave: claveEncriptada,
    };
    // The encrypted data is in the form of bytes, so we print it in base64 format
    // so that it's displayed in a more readable form
    //console.log("encypted data: ", claveEncriptada)

    try {
      var datosLogin = await lastValueFrom(
        await this.usuarioService.loginDGSC(datos)
      );     
      //this.router.navigateByUrl('/dashboard');  
      setTimeout(() => {
        this.loading = false; // Oculta el spinner después de 3 segundos
        this.router.navigateByUrl('/dashboard'); 
        Swal.fire({
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          imageUrl: 'assets/images/iconofinal.png',
          timer: 2000,
          title: 'BIENVENIDO(A) A ESTADO DIGITAL 6',
          customClass: {
            image: 'snack-bar-color'
          }
        });
      }, 1000); // 2000 ms = 2 segundos
  

    } catch (error) {
      this.loading = false; // Ocultar el spinner
      this.router.navigateByUrl("/");
      Swal.fire("Error", error.error.mensaje, "error");
      return;
    }
   // this.router.navigateByUrl("/dashboard");
/*     setTimeout(() => {
      // Finalizamos el inicio de sesión y ocultamos el spinner
      this.loading = false;
      this.router.navigateByUrl('/dashboard');    
      Swal.fire({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        imageUrl: 'assets/images/iconofinal.png',
        timer: 1000,
        title: 'BIENVENIDO(A) A ESTADO DIGITAL 6',
        customClass: {
          image: 'snack-bar-color'
        }
      });
    }, 2000); */

}

}
