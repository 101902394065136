<div mat-dialog-content>
    <mat-card>
        <mat-card-title style="text-align: center; font-size: 20px;" class="main__title">{{tituloC}}</mat-card-title>
        <span style="font-weight: bold;">Rol Actual:</span> {{this.usuario.rol}}
        <!--         <h3 align='center'><strong>{{tituloC}}</strong></h3> -->
        <form action="" [formGroup]="rechazoForm">
            <!--  <h3 align='right'><strong>N°. Trámite : </strong> <strong style="color: rgb(235, 64, 21);">
                    {{tramite?.nrotramite}}</strong>
            </h3> -->
            <hr size="5px">
            <mat-card-content>
                <div class="row" style="font-size: .8rem;">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                            <mat-label>Número de trámite</mat-label>
                            <input matInput type="text" value="{{tramite?.nrotramite}}" readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                            <mat-label>Tipo de trámite</mat-label>
                            <input matInput type="text" value="{{tramite?.nombre_tipo_tramite}}" readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                            <mat-label>Número de Registro</mat-label>
                            <input matInput type="text" value="{{tramite?.registro_numero}}" readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                            <mat-label>Razón Social</mat-label>
                            <input matInput type="text" value="{{tramite?.solicitante}}" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <hr size="5px">

            <div class="row" style="font-size: .8rem;">
                <div class="title-section">IMPROCEDENCIA</div>
                <mat-form-field style="width:100%" appearance="outline">
                    <mat-label>{{subtituloC}}</mat-label>
                    <textarea matInput formControlName="motivo_rechazo" maxlength=2500 rows="10" required></textarea>
                </mat-form-field>
            </div>
            <div mat-dialog-actions class="mt-3 mb-1">
                <button mat-raised-button class="nuevo-button" [disabled]="rechazoForm.invalid"
                    (click)="rechazarTramite()">
                    <mat-icon>save</mat-icon>Guardar
                </button>
                <button mat-raised-button color="warn" style="height: 45px;" (click)="cancelar()">
                    <mat-icon>cancel</mat-icon>Cancelar
                </button>
            </div>
        </form>
    </mat-card>
</div>