import { NavComponent } from './navbar/navbar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransaccionPagoComponent } from './transaccion-pago/transaccion-pago.component';
import { PinFDComponent } from './pin-fd/pin-fd.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisorPdfDialogoComponent } from './visor-pdf-dialogo/visor-pdf-dialogo.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { VisorPdfMultipleComponent } from './visor-pdf-multiple/visor-pdf-multiple.component';
import { CargaDocumentosComponent } from './carga-documentos/carga-documentos.component';
import { CargaImagenesComponent } from './carga-imagenes/carga-imagenes.component';
import { ObservaTramiteComponent } from './observa-tramite/observa-tramite.component';
import { FormularioRechazoComponent } from './formulario-rechazo/formulario-rechazo.component';
import { NavbarPdfComponent } from './navbar-pdf/navbar-pdf.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ReasignaTramiteComponent } from './reasigna-tramite/reasigna-tramite.component';
import { ReasignaTramiteSuperiorComponent } from './reasigna-tramite-superior/reasigna-tramite-superior.component';
import { FormularioObservacionComponent } from './formulario-observacion/formulario-observacion.component';

@NgModule({
  declarations: [
    NavComponent,
    TransaccionPagoComponent,
    PinFDComponent,
    PageNotFoundComponent,
    VisorPdfDialogoComponent,
    VisorPdfMultipleComponent,
    CargaDocumentosComponent,
    CargaImagenesComponent,
    ObservaTramiteComponent,
    FormularioRechazoComponent,
    NavbarPdfComponent,
    NotificacionesComponent,
    ReasignaTramiteComponent,
    ReasignaTramiteSuperiorComponent,
    FormularioObservacionComponent,
 
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
  ],
  exports: [
    TransaccionPagoComponent,
    PinFDComponent,
    PageNotFoundComponent,
    VisorPdfDialogoComponent,
    CargaDocumentosComponent
  ]
})
export class CoreComponentsModule { }
