<div mat-dialog-content>
    <mat-card>
        <h3 align='center'><strong>{{tituloC}}</strong></h3>
        <form action="" [formGroup]="reasignacionForm">
            <h3 align='right'><strong>N°. Trámite : </strong> <strong style="color: rgb(235, 64, 21);">
                    {{tramite?.nrotramite}}</strong>
            </h3>
            <div mat-dialog-content>
                <table class="table table-list2">
                    <thead>
                        <tr>
                            <th><strong>Nombre o Razón Social :</strong> </th>
                            <td>{{tramite?.solicitante}}</td>
                            <th><strong>N° de Registro :</strong></th>
                            <td>{{tramite?.registro_numero}}</td>
                        </tr>
                        <tr>
                            <th><strong>Tipo trámite :</strong></th>
                            <td>{{tramite?.nombre_tipo_tramite}}</td>
                            <th><strong>Oficina :</strong></th>
                            <td>{{tramite?.nombre_oficina}}</td>
                        </tr>
                    </thead>
                </table>
            </div>

            <mat-form-field style="width:100%" appearance="outline">
                <mat-label>{{subtituloC}}</mat-label>
                <textarea matInput formControlName="reasignacion_glosa" rows="5" maxlength="1000" minlength="5"
                    required></textarea>
            </mat-form-field>
            <div mat-dialog-actions class="mt-3 mb-1" >
                
                <button mat-raised-button class="nuevo-button" [disabled]="reasignacionForm.invalid" (click)="GuardarReasignar()">
                    <mat-icon>save</mat-icon>Guardar
                </button>

                <button mat-raised-button color="warn" (click)="cancelar()">
                    <mat-icon>cancel</mat-icon>Cancelar
                </button>


            </div>
        </form>
    </mat-card>
</div>