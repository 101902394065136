import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { compact } from 'lodash';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  private urlBase: string = environment.base_url;
  private base_url_sistema: string = environment.base_url_sistema;
  private urlBaseFD: string = environment.base_urlFD;

  constructor(private httpClient: HttpClient) { }

  /**
   * POST SERVICE
   *
   * @private
   * @template T
   * @param {string} namespace
   * @param {T} payload
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  /*   public post<T>(namespace: string, payload: T): Observable<any> {
      const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
      return this.httpClient.post(url, payload);
    } */
  public post<T>(namespace: string, body: T, payload?: any): Observable<any> {

    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    let payloadData: any;
    if (payload) payloadData = payload;
    return this.httpClient.post(url, body, payloadData);
  }

  /**
   * GET SERVICE
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  /*   public get(namespace: string): Observable<any> {
      const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
      return this.httpClient.get(url);
    } */
  public get(namespace: string, payload?: any): Observable<any> {
    let payloadData: any;
    if (payload) payloadData = payload;
    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    return this.httpClient.get(url, payloadData);
  }

  /**
   * PUT SERVICE
   *
   * @private
   * @template T
   * @param {string} namespace
   * @param {T} payload
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public put<T>(namespace: string, payload: T): Observable<any> {
    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    return this.httpClient.put(url, payload);
  }

  /**
   * DELETE SERVICE
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public delete(namespace: string): Observable<any> {
    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    return this.httpClient.delete(url);
  }

  /**
   * POST CUSTOM SERVICE
   * 
   * @param {string}  namespace 
   * @param {T} body 
   * @param {any} payload 
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public postCustom<T>(namespace: string, body: T, payload?: any): Observable<any> {

    const url = encodeURI(compact([this.base_url_sistema, namespace]).join('/'));
    let payloadData: any;
    if (payload) payloadData = payload;
    return this.httpClient.post(url, body, payloadData);
  }

  /**
   * GET CUSTOM SERVICE
   *
   * @param {string} namespace
   * @param {any} payload 
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public getCustom(namespace: string, payload?: any): Observable<any> {
    let payloadData: any;
    if (payload) payloadData = payload;
    const url = encodeURI(compact([this.base_url_sistema, namespace]).join('/'));
    return this.httpClient.get(url, payload);
  }

  /**
   * POST UPLOAD SERVICE
   *
   * @private
   * @template T
   * @param {string} namespace
   * @param {T} payload
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public postUpload<T>(namespace: string, payload: T): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    return this.httpClient.post(url, payload, { headers: headers });
  }


  /**
   * GET DOWNLOAD SERVICE
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public getDownload(namespace: string): Observable<any> {
    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  /**
   * POST SERVICE WITH FIRMA DIGITAL URL
   *
   * @private
   * @template T
   * @param {string} namespace
   * @param {T} payload
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public postFD<T>(namespace: string, payload: T): Observable<any> {
    const url = encodeURI(compact([this.urlBaseFD, namespace]).join('/'));
    return this.httpClient.post(url, payload);
  }

  /**
   * GET SERVICE WITH FIRMA DIGITAL URL
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public getFD(namespace: string): Observable<any> {
    const url = encodeURI(compact([this.urlBaseFD, namespace]).join('/'));
    return this.httpClient.get(url);
  }

  /**
   * POST SERVICE WITH RESPONSE_TYPE BLOB
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public postBlob<T>(namespace: string, payload: T): Observable<any> {
    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    return this.httpClient.post(url, payload, { responseType: 'blob' as 'json' });
  }

  /**
   * GET SERVICE WITH RESPONSE_TYPE BLOB
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public getBlob<T>(namespace: string): Observable<any> {
    const url = encodeURI(compact([this.urlBase, namespace]).join('/'));
    return this.httpClient.get(url, { responseType: 'blob' as 'json' });
  }

}
