<div class="auth-container__body">
    <div class="body__lead">
   <!--  <h4 class="body__title" style="color: rgb(82, 80, 80); font-size: 16px;"><strong>Bienvenido a SIRCOF - Servidor Público</strong> 
       </h4>       -->
</div>
<div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

    <div class="auth-container__form">        
        <form [formGroup]="loginForm" novalidate (ngSubmit)="login()" autocomplete="off">
            <p>Ingrese sus credenciales de acceso</p>
            <mat-form-field appearance="outline" class="header">
                <mat-label>Usuario de Acceso</mat-label>
                <input matInput placeholder="Usuario de Acceso" formControlName="cuenta" cdkFocusInitial autocomplete="true" (input)="eliminarEspacios()">
            </mat-form-field>

            <mat-form-field appearance="outline" class="header">
                <mat-label>Contraseña de Acceso</mat-label>
                <input [type]="hide ? 'password' : 'text'" matInput placeholder="Contraseña de Acceso" formControlName="clave" name="clave" autocomplete="true" (input)="eliminarEspacios()">
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Ocultar clave'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
            </mat-form-field>

            <button mat-raised-button  class="button-login nuevo-button" type="submit" [disabled]="loginForm.invalid">
       INGRESAR
      </button>
        </form>
    </div>
</div>
<br><br>
<div class="auth-container__footer">
    <div class="footer__option">
        ¿No tiene cuenta?
        <!-- <a routerLink="/verifica">Habilita tu cuenta</a> -->
        <a routerLink="/registro-usuario">Regístrate</a>
        
    </div>
    <div class="footer__option">
        ¿Olvido su contraseña?
        <a routerLink="/recuperar-clave"> Restablecer</a>
    </div>
</div>