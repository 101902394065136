import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BaseHttpService } from './base-http.service'

@Injectable({
  providedIn: 'root'
})
export class PagosService {

  constructor(private baseHttpService: BaseHttpService) { }

  guardarComprobantes(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/guardar-comprobantes", datos)
  }

  verificarPagoTramite(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/verificar-pago-tramite", datos)
  }

  obtenerCostoComprobantes(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/obtener-costo-comprobantes", datos)
  }

  obtenerComprobante(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/obtener-comprobante", datos)
  }

  validarComprobantes(comprobantes: any): Observable<any> {
    return this.baseHttpService.post('tramites/pagos/validar-comprobantes', comprobantes)
  }
}
