<div class="main__header">
  <mat-card-subtitle class="subtitle"><strong>CAMBIO DE CONTRASEÑA.</strong></mat-card-subtitle>
<!--   <div class="main__subtitle">
    Por favor ingrese contraseña actual.
  </div> -->
</div>

<form [formGroup]="resetPasswordForm" autocomplete="off">
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Actual contraseña</mat-label>
        <input matInput [type]="hideOld ? 'password' : 'text'" formControlName="oldPassword" name="oldPassword">
        <button type="button" mat-icon-button matSuffix (click)="hideOld = !hideOld" [attr.aria-label]="'Ocultar clave'"
          [attr.aria-pressed]="hideOld">
          <mat-icon>{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
  </div>
  <div class="row mt-3">
    <div class="col">
      Por favor, ingrese su nueva contraseña a continuación.
      <p>
        <small style="font-size:.6rem;line-height:0rem;color: rgb(50, 137, 219);">*La nueva contraseña debe tener al menos 8 caracteres y debe incluir letras, números y al menos uno de los siguientes caracteres especiales: !@#$%^&*-. Por favor, elija una contraseña que cumpla con estos requisitos.</small>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Nueva contraseña</mat-label>
        <input matInput type="password" formControlName="password" name="password" [type]="hide ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Ocultar clave'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <!-- <mat-error *ngIf="addressForm.controls['city'].hasError('required')"></mat-error> -->
        <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required')">
          Nueva contraseña requerida
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('minlength')">
          Debe contener mínimo 8 caracteres
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('pattern')">
          No contiene los caracteres requeridos
        </mat-error>
      </mat-form-field>
    </div>
  </div>
    <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Confirmar nueva contraseña</mat-label>
        <input matInput type="password" formControlName="passwordConfirm" name="password-confirm"
          [type]="hideTwo ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hideTwo = !hideTwo" [attr.aria-label]="'Ocultar clave'"
          [attr.aria-pressed]="hideTwo">
          <mat-icon>{{hideTwo ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="resetPasswordForm.controls['passwordConfirm'].hasError('required')">
          Confirmación de contraseña requerida
        </mat-error>
      </mat-form-field>
      <small *ngIf="passwordNoSonIguales" class="error-message">
        *Las contraseña no coinciden
      </small>
    </div>
    <div class="col-md-4"></div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <button mat-flat-button color="primary" (click)="cambiarPassword()" [disabled]="resetPasswordForm.invalid">Cambiar
        contraseña</button>
    </div>
    <div class="col-md-4"></div>
    <div class="user-container__footer mt-3">
      <div class="footer__option">
        ¿Ya tienes cuenta?
        <a routerLink="/login"> Inicia sesión</a>
      </div>
    </div>
  
  </div>
</form>