<mat-card>
  <div >
    <mat-card-title style="text-align: center; font-size: 20px;"  class="main__title">OBSERVACIÓN DE TRÁMITE</mat-card-title>
  </div><span style="font-weight: bold;">Rol Actual:</span> {{this.usuario.rol}}
  <hr size="5px">
<mat-card-content>   
    <div class="row" style="font-size: .8rem;">
        <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                <mat-label>Número de trámite</mat-label>
                <input matInput type="text" value="{{tramite?.numero + '/' + tramite?.anio }}" readonly>
            </mat-form-field>
        </div>
   
        <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                <mat-label>Tipo de trámite</mat-label>
                <input matInput type="text" value="{{tramite?.nombre_tipo_tramite}}" readonly>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                <mat-label>Número de Registro</mat-label>
                <input matInput type="text" value="{{tramite?.registro_numero}}" readonly>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline" style="width: 100%;" class="disabled">
                <mat-label>Razón Social</mat-label>
                <input matInput type="text" value="{{tramite?.solicitante}}" readonly>
            </mat-form-field>
        </div>    
    </div>
</mat-card-content>
<hr size="5px">
 

  <div [formGroup]="tramiteObservadoForm">
    <div class="row">
    <div class="col-6">

         <!--  <div class="my-3">
            <label for="">Etapa del trámite a observar:</label>           
          </div> -->
           <mat-form-field appearance="outline" >
            <mat-label>Encargado de la etapa a Subsanar</mat-label>
            <mat-select formControlName="rol_observado_id" (selectionChange)="verEstados($event, 0)">
              <mat-option [value]="rol.id_rol" *ngFor="let rol of rolesObservados">{{rol.nombre_rol}}</mat-option>
            </mat-select>
          </mat-form-field>  

        <div *ngIf="estadosObservados.length > 1">
          <mat-form-field appearance="outline">
            <mat-label>Tarea observada</mat-label>
            <mat-select formControlName="rol_observado_estado">
              <mat-option [value]="estado.id_estado" *ngFor="let estado of estadosObservados">{{estado.nombre_estado}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </div>

      <div class="col-6">
     
         <!--  <div class="my-3">
            <label for="">Una vez corregido/subsanado derivar a:</label>
          </div> -->
          <mat-form-field appearance="outline">
            <mat-label>Encargado de la etapa a Revisión</mat-label>
            <mat-select formControlName="rol_retorno_id" (selectionChange)="verEstados($event, 1)">
              <mat-option [value]="rol.id_rol" *ngFor="let rol of rolesDerivados">{{rol.nombre_rol}}</mat-option>
            </mat-select>
          </mat-form-field>
       

        <div *ngIf="estadosRetorno.length > 1">
          <mat-form-field appearance="outline">
            <mat-label>Tarea a efectuar</mat-label>
            <mat-select formControlName="rol_retorno_estado">
              <mat-option [value]="estado.id_estado" *ngFor="let estado of estadosRetorno">{{estado.nombre_estado}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

 
    <mat-card-content>
      <div class="row" style="font-size: .8rem;"> 
        <div class="title-section">OBSERVACIÓN</div>
        <mat-form-field appearance="outline">
          <mat-label>Escriba su(s) observación(es) detalladamente</mat-label>
          <textarea matInput placeholder="Observación" rows="7" maxlength=1000 formControlName="observacion"
            ></textarea>
        </mat-form-field>
      </div>
      </mat-card-content> 
 

  </div>

<!--   <div class="row my-3 pb-4">
    <div class="col text-end">
      <button mat-flat-button class="mx-3" (click)="cancelar()">Cancelar</button>
      <button mat-raised-button cdkFocusInitial color="primary" (click)="guardar()" class="button button-primary">
        Guardar
      </button>
    </div>
  </div> -->
  <div mat-dialog-actions class="mt-3 mb-1" >
    <button mat-raised-button class="nuevo-button" [disabled]="tramiteObservadoForm.invalid"
    (click)="guardar()">
    <mat-icon>save</mat-icon>Guardar
</button>
    <button mat-raised-button color="warn" style="height: 45px;" (click)="cancelar()">
        <mat-icon>cancel</mat-icon>Cancelar
    </button>
 
</div>

</mat-card>