<button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="cancelar()">
  <mat-icon class="close-icon" matTooltip="Cerrar dialogo">close</mat-icon>
</button>
<button mat-icon-button class="close-button" (click)="minimizar()" *ngIf="isFullScreen">
  <mat-icon class="close-icon" matTooltip="Restaurar pantalla">fullscreen_exit</mat-icon>
</button>
<button mat-icon-button class="close-button" (click)="maximizar()" *ngIf="!isFullScreen">
  <mat-icon class="close-icon" matTooltip="Pantalla completa">fullscreen</mat-icon>
</button>

<div class="row mt-2">
  <div class="col text-center">
    <h2>{{titulo}}</h2>
    <span style="font-size:13px;">{{subtitulo}}</span>
  </div>
</div>

<div class="row" style="border: thin solid #c7c7c7; position: relative;">

  <div *ngIf="tipoArchivo === 'pdf'; then pdfBlock else imageBlock"></div>
  <ng-template #pdfBlock>
    <app-navbar-pdf [base64]="source" [fileName]="fileName"></app-navbar-pdf>

    <div class="pdf-viewer-block" [ngStyle]="{'height.px': pdfViewerHeight}">
      <app-pdf-viewer [source]="source" [base64]="base64" [url]="url"></app-pdf-viewer>
    </div>
  </ng-template>

  <ng-template #imageBlock>
    <div class="pdf-viewer-block" [ngStyle]="{'height.px': pdfViewerHeight, 'text-align': 'center'}">
      <img class="image-box" [src]="source" style="object-fit: contain;" [ngStyle]="{'height.px': pdfViewerHeight}">
    </div>

  </ng-template>
</div>

<div class="row mt-4">
  <div class="col text-end">
    <button mat-stroked-button color="simple-outline" class="mx-3" (click)="cancelar()"
      *ngIf="!disableCancelButton">Cancelar {{enableCancelButton}}</button>
    <button mat-button [ngClass]="botonExtra.tipo=='stroked' ? 'mat-stroked-button' : 'mat-flat-button'" class="mx-3"
      *ngFor="let botonExtra of botonesExtra"
      [color]="botonExtra.tipo=='stroked' ? botonExtra.color + '-outline' : botonExtra.color"
      (click)="accionesExtra(botonExtra.code)">{{ botonExtra.texto }}</button>
    <button mat-raised-button color="primary" class="mx-3" (click)="aceptar()"
      *ngIf="this.textoBoton!='Descargar'">{{textoBoton}}</button>
  </div>
</div>