import { Component, OnInit, Inject,ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { lastValueFrom, Observable } from "rxjs";
import { UsuarioService } from "src/app/modules/auth/services/usuario.service";
import { Parametricas } from "../../enums/parametricas.enums";
import { TramiteService } from "src/app/core/services/tramite.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ReasignaTramiteComponent } from "../reasigna-tramite/reasigna-tramite.component";
@Component({
  selector: "app-reasigna-tramite-superior",
  templateUrl: "./reasigna-tramite-superior.component.html",
  styleUrls: ["./reasigna-tramite-superior.component.scss"],
})
export class ReasignaTramiteSuperiorComponent implements OnInit {
  public reasignacionForm: FormGroup;
  tituloC = Parametricas.TITULOREASIGNACION;
  subtituloC = Parametricas.subtituloReasignacion;
  idTramite: number;
  tramite: any;
  codigo: boolean=false
  ocultar:boolean=false
    /**
   * @ignore
   */
    dataServidores = null
      /**
   * @ignore
   */
  datos: any[]
    /**
   * Listado de cabeceras que se mostraran 
   */
    nombreCabeceras: string[] = ['nro', 'acciones', 'nro_tramite','activo', 'nombres', 'documento', 'nombre_rol']

    /**
   * @ignore
   */
    @ViewChild(MatSort) sort: MatSort;
      /**
   * @ignore
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tramiteService: TramiteService,
    private formBuilder: FormBuilder,
    /**
     * Constructor  que permite consumir los servicios de usuarios
     */
    public _usuarioService: UsuarioService,
     /**
     * objeto dialog para el listado de solicitudes ap
     */
     public dialog: MatDialog,
    public dialogreasigna: MatDialogRef<ReasignaTramiteSuperiorComponent>
  ) {
   // (this.idTramite = data.idTramite),
      (this.reasignacionForm = this.formBuilder.group({
        reasignacion_glosa: [""],
      }));
   //  console.log("dataaaaa",data)
  }

  ngOnInit(): void {
    this.obtenerDatosTramite();
  }
  /**
   * Funcion de busqueda y/o filtro omitiendo mayusculas y minisuclas
   */
  async obtenerDatosTramite() {  
    try {
      var resp = await lastValueFrom(this._usuarioService.obtenerTramitesAsigxUsuario())
    } catch (error) {
      Swal.fire({
        text: error.error.mensaje,
        icon: "warning",
        confirmButtonText: "Aceptar",
      })
      return
    }  
   // console.log("funnn",resp)
    this.datos = resp.datos;
    this.dataServidores = new MatTableDataSource(this.datos)    
    this.dataServidores.paginator = this.paginator
    this.dataServidores.sort = this.sort    
   }
  /**
   * Funcion de busqueda y/o filtro omitiendo mayusculas y minisuclas
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataServidores.filter = filterValue;
  }


  async reasignar(row) {
  // console.log("row",row)
    const dialogReasignacionSuperior=this.dialog.open(ReasignaTramiteComponent,{ disableClose: true,     
      
       data:{     
       idTramite:row.id_tramite,
       idEstado:row.id_estado,
       idTipoTramite:row.id_tipo_tramite   
       }
     })
     var res = await lastValueFrom(dialogReasignacionSuperior.afterClosed())
     if (res == undefined) {
       return
     } else if (res.res == true) {
     
     }
  }
  cancelar() {
    this.dialogreasigna.close({ res: false, code: 0 }); // false: cancelar
  }

}
