import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecuperarClaveComponent } from './pages/recuperarclave/recuperar-clave.component';
import { AuthRoutingModule } from './auth-routing.module';
import { MaterialModule } from './../../material.module';
import { RegistroUsuarioComponent } from './pages/registro-usuario/registro-usuario.component';
import { CambiarcComponent } from './pages/cambiarc/cambiarc.component';
import { LoginsisComponent } from './pages/loginsis/loginsis.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { RegistroPersonaComponent } from './pages/registro-persona/registro-persona.component';
import { CambiarClaveComponent } from './pages/cambiar-clave/cambiar-clave.component';

@NgModule({
  declarations: [
    AuthLayoutComponent,
    CambiarcComponent,
    LoginsisComponent,
    RecuperarClaveComponent,
    RegistroPersonaComponent,
    RegistroUsuarioComponent,
    CambiarClaveComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    PdfViewerModule,
  ],
  providers:[
    DatePipe
  ]
})
export class AuthModule { }
