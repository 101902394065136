export class Parametricas {

    public static meses = [
        { nombre: 'Enero', id: 1 },
        { nombre: 'Febrero', id: 2 },
        { nombre: 'Marzo', id: 3 },
        { nombre: 'Abril', id: 4 },
        { nombre: 'Mayo', id: 5 },
        { nombre: 'Junio', id: 6 },
        { nombre: 'Julio', id: 7 },
        { nombre: 'Agosto', id: 8 },
        { nombre: 'Septiembre', id: 9 },
        { nombre: 'Octubre', id: 10 },
        { nombre: 'Noviembre', id: 11 },
        { nombre: 'Diciembre', id: 12 },
    ];
    public static NUEVO:string= 'Nuevo Registro'
    public static BUSCAR:string= 'Buscar...'
    public static maxFileSize = 1048576 // Tamaño en bytes 1Mb por default
    public static maxImageSize = 2097152 // Tamaño en bytes 2Mb por default
public static authTitle='ESTADO DIGITAL'
public static authSubtitle='DIRECCIÓN GENERAL DE SUSTANCIAS CONTROLADAS'
    public static costoPorPagoPPTE: number = 1 // Costo por pagar atraves de PPTE "Plataforma de Pagos de Tramites del Estado"
    public static MesInsp = 'Por favor, seleccione o agregue al responsable o encargado que presencio y se llevó a cabo la inspección.'
    
    public static oficinas = [
        { nombre_oficina: 'CENTRAL', id_oficina: 0 },
        { nombre_oficina: 'LA PAZ', id_oficina: 1 },
        { nombre_oficina: 'ORURO', id_oficina: 2 },
    ]

    public static CODIGO_PAIS_BOLIVIA = 25

    public static NACIONALIDAD_BOLIVIANA_ID = 1
    public static NACIONALIDAD_BOLIVIANA_NOMBRE = 'Boliviano'
    public static NACIONALIDAD_BOLIVIANA_ABBR = 'BO'
  
    public static NACIONALIDAD_EXTRANJERA_ID = 2
    public static NACIONALIDAD_EXTRANJERA_NOMBRE = 'Extranjero'
    public static NACIONALIDAD_EXTRANJERA_ABBR = 'EX'
        
  // Tipos de codigo de verificacion
  public static ACTIVACION = 1
  public static RESET_PASSWORD = 2

  // Tipos de pago
  public static PAGO_POR_BOLETAS = 0
  public static PAGO_POR_PPTE = 1

  // Tipos de documento
  public static OBLIGATORIO = 'OBLIGATORIO'
  public static ADICIONAL = 'ADICIONAL'

  public static ANIO_MINIMO_DE_DECLARACION = 2005

    public static ROL_OBSERVADO = 0
    public static ROL_DERIVADO = 1
    public static TITULOANULAR = "ANULAR IMPROCEDENCIA DE TRÁMITE"
    public static TITULO = "IMPROCEDENCIA DE TRÁMITE"
    public static TITULO_INSPECCION = "INSPECCIÓN TÉCNICA"
    public static TITULOREASIGNACION = "REASIGNAR TRÁMITE"
    public static TITULO_OBSERVACION = "OBSERVACION"
    public static subtituloReasignacion = "Motivo de la reasignación"
    public static subtitulo = "Motivo de la Improcedencia"
    public static subtitulo_observacion = "Motivo de la Observacion"
    public static btnRechazado = "Improceder"
    public static btnAnulado = "Anular Improcedencia"
    public static btnObservado = "Observar"
    public static btnAprobar = "Aprobar"
    public static CREAR: string = 'crear'
    public static ACTUALIZAR: string = 'actualizar'
    public static ELIMINAR: string = 'eliminar'
    public static AGREGAR_EXISTENTE: string = 'agregar_existente'
    
    public static headerTitle: string = 'INFORMACIÓN DEL TRÁMITE';
    public static headerSubtitle: string = 'Verifique y complete la información del trámite asignado';
}