<div mat-dialog-content>
    <mat-card>
        <h3 align='center'><strong>{{tituloC}}</strong></h3>
      <!--   <form action="" [formGroup]="reasignacionForm"> -->
     

      
        <div class="row main__search">         
            <div class="col-md-6 column">            
            </div>
            <div class="col-md-6 column" style="text-align: right;">
                
              <mat-icon matSuffix>search</mat-icon>
              <mat-form-field style="width:50%" appearance="outline">
                <mat-label>Buscar</mat-label>
                <input matInput #filter (keyup)="applyFilter($event.target.value)">
                <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter.value"
                  (click)="filter.value=''; applyFilter('');">
                  <mat-icon matSuffix>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          
        <div class="table-responsive">
          <table mat-table [dataSource]="dataServidores" matSort>
            
            <ng-container matColumnDef="nro">
                <th mat-header-cell *matHeaderCellDef> N° </th>
                <td mat-cell *matCellDef="let row; let i = index">
                    {{ i + 1}}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let row; let i = index">
                    <button mat-icon-button matTooltip="Reasignar Trámite" style="text-align:left ;"
                    matTooltipClass="tooltip-background" (click)="reasignar(row)" >
                    <img src="assets/svg/transfer-svgrepo-com.svg" alt="" height="25" width="25">
                  </button>
                </td>
            </ng-container>
    
    
      
            <ng-container matColumnDef="activo" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row">
                  <div class="status-container true" *ngIf="row.activo"><span>Activo</span> </div>
                  <div class="status-container false" *ngIf="!row.activo"><span>No activo</span> </div>
                </td>
            </ng-container> 
           
            <ng-container matColumnDef="cuenta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
                <td mat-cell *matCellDef="let row" class="lower-case" > {{row.cuenta}} </td>
            </ng-container>
            <ng-container matColumnDef="documento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
                <td mat-cell *matCellDef="let row"> {{row.documento}} </td>
            </ng-container>
    
            <ng-container matColumnDef="nombre_rol">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rol </th>
                <td mat-cell *matCellDef="let row"> {{row.nombre_rol}} </td>
            </ng-container>
    
            <ng-container matColumnDef="nombres" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Servidor Público </th>
                <td mat-cell *matCellDef="let row" class="upper-case"> {{row.nombres}} </td>
            </ng-container>    
            <ng-container matColumnDef="nro_tramite">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> N° Trámite </th>
                <td mat-cell *matCellDef="let row" class="upper-case"> {{row.nro_tramite}} </td>
            </ng-container>    
            <tr mat-header-row *matHeaderRowDef="nombreCabeceras" class="header-row"></tr>
            <tr mat-row *matRowDef="let row; columns: nombreCabeceras;"></tr>    
      
    
        </table>
        </div>

        <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons
        aria-label="Seleccione páginas de un periodo elementos">
      </mat-paginator>
<!-- 
            <mat-form-field style="width:100%" appearance="outline"  [hidden]="codigo">
                <mat-label>{{subtituloC}}</mat-label>
                <textarea matInput formControlName="reasignacion_glosa" rows="5"  maxlength="1000" minlength="5"required></textarea>
            </mat-form-field> -->
            <div mat-dialog-actions class="mt-3 mb-1" >
          <!--       <button mat-raised-button class="nuevo-button" [disabled]="reasignacionForm.invalid"
                >
                <mat-icon>save</mat-icon>Guardar
            </button> -->
                <button mat-raised-button color="warn" (click)="cancelar()">
                    <mat-icon>cancel</mat-icon>Cancelar
                </button>

               
            </div>
      <!--   </form> -->
    </mat-card>
</div>